import RemanejamentoFormulario from "./tab/formulario";

const RemanejamentoInscricaoOnlineForm = ({dataAbertura, dataFechamento}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "24px 48px",
      }}
    >
      <img
        src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
        width="200"
        height="150"
        style={{ display: "flex", alignSelf: "center" }}
      />
      <h6 style={{ margin: "15px", textAlign: "center" }}>
        PREFEITURA MUNICIPAL DE ARARUAMA
      </h6>
      <h4 style={{ textAlign: "center" }}>SECRETARIA DE EDUCAÇÃO</h4>
      <h2 style={{ margin: "15px", textAlign: "center" }}>
        REMANEJAMENTO DE ALUNOS 2024-2025
      </h2>
      <h2 style={{ margin: "15px", textAlign: "center" }}>
        SOMENTE PARA ALUNOS DAS UNIDADES DE ENSINO QUE NÃO ATENDEM O SEGMENTO
        NECESSÁRIO À MATRÍCULA 2025.
      </h2>
      <div className="row">
        <div className="mailbox-container">
          <div className="container-fluid">
            <div className="row">
              <div className="mailbox-open-content col-md-12 m-0 p-3">
                <div className="tab-content" id="myTabContent">
                  <RemanejamentoFormulario  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemanejamentoInscricaoOnlineForm;
