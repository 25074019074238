import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../../../../components/breadcrumb";
import BaseRemanejamento from "../../baseRemanejamento";
import urlsRemanejamento from "../../urlsRemanejamento";
import { indeferirInscricaoRemanejamento } from "../../../../../../services/processos/remanejamento";
import { useMutation } from 'react-query';
import FormInput from "../../../../../../components/formInput";

const RemanejamentoIndeferir = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [status, setStatus] = useState(0)
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(indeferirInscricaoRemanejamento, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsRemanejamento.inscricoes);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar indeferir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  console.log(id)

  const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO
  const onSubmit = data => {
    mutate(data);
  }

  useEffect(() => {
    setValue('id', id)
  }, [setValue])

  return (
    <BaseRemanejamento>
      <Breadcrumb title={'Exclusão'} itens={['Gestão', 'Seduc', 'Alunos', 'Exclusão']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-1'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="id"
                        label="ID"
                        readOnly
                      />
                    </div>

                    <div className='col-7'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="motivo"
                        label="Motivo Indeferimento"
                        required={false}
                      />
                    </div>

                    <div className="col-4">
                <div className="form-group">
                  <label htmlFor="status">Status<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                  <select
                    className="form-select"
                    id="status"
                    style={{ maxHeight: '100px', overflow: 'auto' }}
                    {...register('status', { required: true })}
                  >
                   <option value="0"></option>
                <option value="1">EM ANÁLISE</option>
                <option value="2">CANCELADO</option>
                <option value="3">DEFERIDO</option>
                <option value="4">INDEFERIDO</option>
                  </select>
                  {errors?.cidade?.type === 'required' && (
                    <span className="text-danger">Campo obrigatório</span>
                  )}
                </div>
              </div>


                    
                  </div>

             

                </>
              </div>
              <div className='card-footer text-end'>
                <button type='submit' className='btn btn-warning' disabled={!isValid}>
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseRemanejamento>
  )

};

export default RemanejamentoIndeferir;