export const perguntasIniciaisPre = [
    {
        id: 69,
        nome: 'Demonstra empatia pelos outros, percebendo que as pessoas têm diferentes sentimentos, necessidades e maneiras de pensar e agir.',
        disciplinaId: 29,
    },
    {
        id: 70,
        nome: 'Age de maneira independente, com confiança em suas capacidades, reconhecendo suas conquistas e limites.',
        disciplinaId: 29,
    },
    {
        id: 71,
        nome: 'Amplia as relações interpessoais, desenvolvendo atitudes de participação e cooperação. Comunica suas ideias e sentimentos a pessoas e grupos diversos.',
        disciplinaId: 29,
    },
    {
        id: 72,
        nome: 'Demonstra valorização das características de seu corpo e respeitar as características dos outros (crianças e adultos) com os quais convive.',
        disciplinaId: 29,
    },
    {
        id: 73,
        nome: 'Manifesta interesse e respeito por diferentes culturas e modos de vida.',
        disciplinaId: 29,
    },
    {
        id: 74,
        nome: 'Usa estratégias mútuas para lidar com conflitos nas interações com adultos e crianças.',
        disciplinaId: 29,
    },
    {
        id: 75,
        nome: 'Cria com o corpo formas diversificadas de expressão de sentimentos, sensações e emoções, tanto nas situações do cotidiano quanto em brincadeiras, dança, teatro, música.',
        disciplinaId: 30,
    },
    {
        id: 76,
        nome: 'Demonstra controle e adequação do uso de seu corpo em brincadeiras e jogos, escuta e reconto de histórias, atividades artísticas, entre outras possibilidades.',
        disciplinaId: 30,
    },
    {
        id: 77,
        nome: 'Cria movimentos, gestos, olhares e mímicas em brincadeiras, jogos, dança, teatro e atividades artísticas.',
        disciplinaId: 30,
    },
    {
        id: 78,
        nome: 'Adota hábitos de autocuidado relacionados a higiene, alimentação, conforto e aparência.',
        disciplinaId: 30,
    },
    {
        id: 79,
        nome: 'Coordena suas habilidades manuais no atendimento adequado a seus interesses e necessidades em situações diversas.',
        disciplinaId: 30,
    },
    {
        id: 80,
        nome: 'Utiliza sons produzidos por materiais, objetos e instrumentos musicais durante brincadeiras de faz de conta, encadernação, criações musicais, festa.',
        disciplinaId: 31,
    },
    {
        id: 81,
        nome: 'Expressa-se livremente por meio de desenhos, pintura, colagem, dobradura e escultura, criando produções bidimensionais e tridimensionais.',
        disciplinaId: 31,
    },
    {
        id: 82,
        nome: 'Reconhecer as qualidades do som (intensidade, duração, altura e timbre), utilizando-as em suas produções.',
        disciplinaId: 31,
    },
    {
        id: 83,
        nome: 'Expressa ideias, desejos sobre suas vivências, por meio da linguagem oral e escrita espontânea, de fotos, desenhos e outras formas de expressão.',
        disciplinaId: 32,
    },
    {
        id: 84,
        nome: 'Inventa brincadeiras cantadas, poemas e canções, criando rimas, alterações e ritmos. Escolhe e folheia livros, procurando orientar-se por temas, ilustrações e tentando identificar palavras conhecidas.',
        disciplinaId: 32,
    },
    {
        id: 85,
        nome: 'Reconta histórias ouvidas e planejadas coletivamente roteiros de vídeos, e de encenações, definindo contextos, os personagens, a estrutura da história.',
        disciplinaId: 32,
    },
    {
        id: 86,
        nome: 'Reconta histórias ouvidas para produção de reconto escrito, tendo o professor como escriba.',
        disciplinaId: 32,
    },
    {
        id: 87,
        nome: 'Produz suas próprias histórias orais e escritas, em situações com função social significativa.',
        disciplinaId: 32,
    },
    {
        id: 88,
        nome: 'Levanta hipóteses sobre gêneros textuais veiculados em portadores conhecidos, recorrendo a estratégias de observação gráfica e ou leitura.',
        disciplinaId: 32,
    },
    {
        id: 89,
        nome: 'Seleciona livros e textos de gêneros conhecidos para leitura.',
        disciplinaId: 32,
    },
    {
        id: 90,
        nome: 'Levanta hipóteses em relação à linguagem escrita, realizando registros de palavras e textos, por meio de escrita espontânea.',
        disciplinaId: 32,
    },
    {
        id: 91,
        nome: 'Estabelece relações de comparação entre objetos, observando suas propriedades. Observa e descreve mudança em diferentes materiais resultantes de ações sobre eles, em experimentos envolvendo fenômenos naturais e artificiais.',
        disciplinaId: 33,
    },
    {
        id: 92,
        nome: 'Identifica e seleciona fontes de informações para responder a questões sobre a natureza, seus fenômenos e sua conservação.',
        disciplinaId: 33,
    },
    {
        id: 93,
        nome: 'Registra observações, manipulações e medidas usando múltiplas linguagens (desenho, registro por números ou escrita espontânea).',
        disciplinaId: 33,
    },
    {
        id: 94,
        nome: 'Classifica objetos e figuras de acordo com suas semelhanças e diferenças.',
        disciplinaId: 33,
    },
    {
        id: 95,
        nome: 'Relata fatos importantes sobre seu nascimento e desenvolvimento, a história dos seus familiares e da sua comunidade.',
        disciplinaId: 33,
    },
    {
        id: 96,
        nome: 'Relaciona números às suas respectivas quantidades e identifica o antes, o depois e o entre em uma sequência.',
        disciplinaId: 33,
    },
    {
        id: 97,
        nome: 'Expressa medidas (peso, altura, etc.) construindo gráficos básicos.',
        disciplinaId: 33,
    },
];
