import BaseBilingue from "../BaseBilingue";

const BilingueAtualizarIncricaoPage = () => {
    return (
        <>
            <BaseBilingue>
                <div>
                    <h1>Atualizar Inscrição</h1>
                </div>
            </BaseBilingue>
        </>
    );
};

export default BilingueAtualizarIncricaoPage;