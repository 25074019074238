import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../../../../components/breadcrumb";
import BaseServidores from "../../../public/baseServidores";
import urlsServidores from "../../../public/urlsServidores";
import { atualizarStatusInscricaoServidor } from "../../../../../../services/processos/remanejamento";
import { useMutation } from 'react-query';
import FormInput from "../../../../../../components/formInput";

const ServidoresIndeferir = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [status, setStatus] = useState(0)
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(atualizarStatusInscricaoServidor, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsServidores.inscricoes);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar indeferir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  console.log(id)

  const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO
  const onSubmit = data => {
    mutate(data);
  }

  useEffect(() => {
    setValue('id', id)
  }, [setValue])

  return (
    <BaseServidores>
      <Breadcrumb title={'Exclusão'} itens={['Gestão', 'Processo Seletivoç Servidores', 'Atualizar']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-4'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="id"
                        label="ID"
                        readOnly
                      />
                    </div>

                    <div className='col-5'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="motivo"
                        label="Observação"
                        required={false}
                      />
                    </div>

                    <div className="col-3">
                <div className="form-group">
                  <label htmlFor="status">Status<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                  <select
                    className="form-select"
                    id="status"
                    style={{ maxHeight: '100px', overflow: 'auto' }}
                    {...register('status', { required: true })}
                  >
                   <option value="0"></option>
                   <option value={1}>AGUARDANDO</option>
                  <option value={2}>HOMOLOGADO</option>
                  <option value={3}>CONVOCADO</option>
                  <option value={4}>NÃO HOMOLOGADO</option>
                  <option value={5}>NÃO COMPARECEU</option>
                  <option value={6}>RECURSO ACEITO</option>
                  <option value={7}>RECURSO NEGADO</option>
                  <option value={8}>CANCELADO</option>
                  </select>
                  {errors?.cidade?.type === 'required' && (
                    <span className="text-danger">Campo obrigatório</span>
                  )}
                </div>
              </div>


                    
                  </div>

                  <div 
                    style={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      marginRight:30,
                      marginTop: 20,
                     }}>
                    <button 
                    style={{marginRight: 20}} 
                    type='button' 
                    className='btn btn-warning' 
                    onClick={() => navigate(-1)}>
                        Voltar
                      </button>
                      <button type='submit' className='btn btn-success' disabled={!isValid}>
                        Atualizar
                      </button>
                    </div>

             

                </>
              </div>
              
            </div>
          </div>
        </div>
      </form>
    </BaseServidores>
  )

};

export default ServidoresIndeferir;
