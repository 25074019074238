import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../../../../components/breadcrumb'
import Table from '../../../../../components/table'
import BaseBilingue from '../baseBilingue'
import urlsBilingue from '../urlsBilingue'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

import { listarInscricoes, listarMunicipios, listarPolos, gerarRelatorioInscricoes, indeferirInscricao, listaAnexo, downloadAnexo} from '../../../../../services/processos/remanejamento'
import { getUnidades } from '../../../../../services/unidades'
import { useQuery, useMutation } from 'react-query';

const BilingueInscricoes = () => {
  const navigate = useNavigate()
  // const [search, setSearch] = useState('')
  const [pesquisarResponsavel, setPesquisarResponsavel] = useState('')
  const [pesquisarAluno, setPesquisaAluno] = useState('')
  const [pesquisaCPF, setpesquisaCPF] = useState('')
  const [data, setData] = useState([])

  const [unidade, setUnidade] = useState(0)
  const [municipio, setMunicipio] = useState(0)
  const [polo, setPolo] = useState(0)
  const [escolaOrigem, setEscolaOrigem] = useState(0)
  const [escolaPretendida, setEscolaPretendida] = useState(0)
  const [deficiente, setDeficiente] = useState(0)
  const [status, setStatus] = useState(0)
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);

  const { mutate } = useMutation(indeferirInscricao, {
    onSuccess: (message) => {
      toast.success(message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar excluir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { isError, isLoading, refetch } = useQuery(
    'inscricoes',
    () => listarInscricoes(municipio, polo, escolaOrigem, deficiente, status, pesquisaCPF, pesquisarAluno, pesquisarResponsavel, escolaPretendida, skip,limit),
    {
      retry: 0,
      enabled: false,
      onSuccess: data => {
        console.log(data)
        setData(data)
      }
    }
  )

  const { data: unidades, isLoading: loadingUnidades } = useQuery('getUnidades', () => getUnidades(), {
    enabled: true,
    retry: true
  })
  
  const { data: municipiosData} = useQuery('listarMunicipios', () => listarMunicipios(), {
    enabled: true,
    retry: true
  })

  const { data: polosData} = useQuery('listarPolos', () => listarPolos(), {
    enabled: true,
    retry: true
  })

  const findMunicipio = async (id) => {
    if (id) {
      const municipio = municipiosData?.data.find((m) => m.id === id);

      if(municipio == undefined || municipio == null){
        return ""
      }
      return municipio.descricao;
    }
  }

  const handleLaudoView = async (id, nome, anexoId) => {
    let anexo = await downloadAnexo(id, nome, anexoId)
  }

  // Filtros abaixo


  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      {
        Header: 'Nascimento', accessor: 'dataNascimento',
        Cell: ({ row }) => (
          row.original.dataNascimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      // {
      //   Header: 'Escola', accessor: 'unidadeId',
      //   Cell: ({ row }) => (
      //     findMunicipio(row.original.unidadeId)
      //   )
      // },
      { Header: 'Cidade', accessor: 'cidade' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <span className={`badge bg-dark`}>
            {row.original.status === 1 ? 'EM ANÁLISE' :
            row.original.status === 2 ? 'CANCELADO' :
            row.original.status === 3 ? 'DEFERIDO' :
            row.original.status === 4 ? 'INDEFERIDO' : ''}
          </span>
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {row.original.status > 0 && (
              <button onClick={() => navigate(urlsBilingue.indeferir + '/' + row.original.id, {
                state: {
                  id: row.original.id,
                }
              })} className='btn btn-sm btn-warning'>
                <i className="material-icons md-edit_note"></i> atualizar
              </button>
              
            )}
            {row.original.anexoId && (
              <button
              onClick={() =>
                handleLaudoView(row.original.id, row.original.nome, row.original.anexoId)
              }
              className="btn btn-sm btn-secondary"
            >
              <i className="material-icons md-edit_note"></i> laudo
            </button>
              
            )}
            <button
              onClick={() =>
                {  window.open(urlsBilingue.visualizar + row.original.id, '_blank')}
                // navigate(urls.processosSeletivosVInscricao + row.original.id)
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
          </>
        )
      }
    ],
    [navigate, mutate]
  )

  useEffect(() => {
    refetch()
    // refetchEscolaridades()
  }, [refetch, limit, skip])

  const handleSearch = () => {
    refetch()
  }

  const handleDownloadExcel = async () => {
    await gerarRelatorioInscricoes(municipio, polo, escolaOrigem, deficiente, status, pesquisaCPF, pesquisarAluno, pesquisarResponsavel, escolaPretendida)
  }



  return (
    <BaseBilingue>
      <Breadcrumb
        title={'Inscrições'}
        itens={['Processo Seletivo', 'Inscrições', 'Lista Geral']}
        hasExcelButton 
        onClickDownload={() => handleDownloadExcel()}
        // actions={{
        //   link: urls.processoSeletivoInscricoesCadastrar,
        //   text: 'Nova Inscição'
        // }}
      />

      <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="municipioId" className="form-label">
            Unidades Escolares
            </label>
            <select
              className="form-select"
              id="municipioId"
              // value={unidadeId}
              onChange={async e => {
                await setMunicipio(e.target.value)
              }}
            >
              {loadingUnidades ? (
                <option value="">SELECIONE</option>
              ) : (
                <>
                  <option value="0">TODAS</option>
                  {unidades &&
                    municipiosData?.data.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="municipioId" className="form-label">
              Polos
            </label>
            <select
              className="form-select"
              id="municipioId"
              // value={unidadeId}
              onChange={async e => {
                await setPolo(e.target.value)
              }}
            >
              {loadingUnidades ? (
                <option value="">SELECIONE</option>
              ) : (
                <>
                  <option value="0">TODAS</option>
                  {polosData &&
                    polosData?.data.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>
        
        

        

        

        

      </div>

      <div className="row" style={{ marginBottom: 20 }}>
      {/* <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="escolaOrigemId" className="form-label">
            Escola de Origem
            </label>
            <select
              className="form-select"
              id="escolaOrigemId"
              // value={unidadeId}
              onChange={async e => {
                await setEscolaOrigem(e.target.value)
              }}
            >
              {loadingUnidades ? (
                <option value="">SELECIONE</option>
              ) : (
                <>
                  <option value="0">TODAS</option>
                  <option value={1}>MUNICIPAL</option>
                  <option value={2}>PRIVADA</option>
                </>
              )}
            </select>
          </div>
        </div> */}

        {/* <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="escolaOrigemId" className="form-label">
            Escola Pretendida
            </label>
            <select
              className="form-select"
              id="escolaOrigemId"
              // value={unidadeId}
              onChange={async e => {
                await setEscolaPretendida(e.target.value)
              }}
            >
              {loadingUnidades ? (
                <option value="">SELECIONE</option>
              ) : (
                <>
                  <option value="0">TODAS</option>
                  <option value={1}>MUNICIPAL SUELI AMARAL (ORIENTAÇÃO MILITAR)</option>
                  <option value={2}>MUNICIPAL GETÚLIO VARGAS (GASTRONOMIA E HOTELARIA)</option>
                </>
              )}
            </select>
          </div>
        </div> */}

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="municipioId" className="form-label">
              Deficiente
            </label>
            <select
              className="form-select"
              id="municipioId"
              // value={unidadeId}
              onChange={async e => {
                await setDeficiente(e.target.value)
              }}
            >
              {loadingUnidades ? (
                <option value="">SELECIONE</option>
              ) : (
                <>
                <option value="0"></option>
                  <option value={1}>SIM</option>
                  <option value={2}>NÃO</option>
                </>
              )}
            </select>
          </div>
        </div>
        
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="status" className="form-label">
              Status?
            </label>
            <select
              className="form-select"
              id="status"
              value={status}
              onChange={async e => {
                await setStatus(e.target.value)
              }}
            >
                <>
                <option value="0"></option>
                <option value="1">EM ANÁLISE</option>
                <option value="2">CANCELADO</option>
                <option value="3">DEFERIDO</option>
                <option value="4">INDEFERIDO</option>
                </>
            </select>
          </div>
        </div>

        

        

        

      </div>


      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 20
        }}
      >

        <div className="form-group" style={{ width: '100%', maxWidth: 390, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="CPF"
            
            value={pesquisaCPF}
            onChange={e => setpesquisaCPF(e.target.value)}
          />
        </div>

        <div className="form-group" style={{ width: '100%', maxWidth: 395, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={pesquisarAluno}
            onChange={e => setPesquisaAluno(e.target.value)}
          />
        </div>

        <div className="form-group" style={{ width: '100%', maxWidth: 395 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome Reponsável"
            value={pesquisarResponsavel}
            onChange={e => setPesquisarResponsavel(e.target.value)}
          />
        </div>
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons md-search"></i> Buscar
          </button>
        </div>
      </div>



      
      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
            />
          </div>
        </div>
      </div>
    </BaseBilingue>
  )
}

export default BilingueInscricoes
