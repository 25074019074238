import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Box, Paper, TextField, Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';  // Import the react-to-print hook
import './style.css';  // Import your CSS file
import { listarFormularioBilingue } from '../../../../services/formulariosService';

function FormularioBilingue2024Visualizacao() {
  const { id } = useParams();
  const [formValues, setFormValues] = useState({});
  const componentRef = useRef();  // Use ref for the component to print

  useEffect(() => {
    const fetchFormulario = async () => {
      try {
        const response = await listarFormularioBilingue(id);
        setFormValues(response?.data);
      } catch (error) {
        console.error('Erro ao buscar formulário:', error);
      }
    };
    fetchFormulario();
  }, [id]);

  // Use the react-to-print hook to handle printing
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,  // Define the content to print
    documentTitle: 'FormularioBilingue2024',  // Optional: define a document title
  });

  return (
    <Box className="print-wrapper">
      {/* Use the ref for the content you want to print */}
      <Paper id="printContent" className="paper-content" ref={componentRef}>
        <Box className="logo-container" sx={{ textAlign: 'center' }}>
          <img
            id="logo"
            src="https://app.educacaoararuama.rj.gov.br/assets/images/prefeituras/seduc.png"
            alt="Logo Prefeitura de Araruama"
          />
          <Typography variant="h5">Inscrição Bilíngue 2024</Typography>
          <Typography variant="h5">
            {formValues.tipoFormulario === 'BILINGUE_MILITAR'
              ? 'Escola Bilíngue M. Sueli Amaral (Orientação Militar)'
              : formValues.tipoFormulario === 2
              ? 'Escola Bilíngue M. Prof. João Raposo (Orientação em Gastronomia e Hotelaria)'
              : 'Escola Bilíngue M. Oscarino Pereira de Andrade (Orientação em Agronegócio e Turismo Rural)'}
          </Typography>
          <Typography variant="h6">Inscrição: <b>{id}</b> | Realizada em: <b>{new Date(formValues.dataCriacao).toLocaleDateString('pt-BR')}</b> às <b>{new Date(formValues.dataCriacao).toLocaleTimeString('pt-BR')}</b></Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Dados Pessoais</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Nome Completo"
              value={formValues.nomeCompleto || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Data de Nascimento"
              value={new Date(formValues.dataNascimento).toLocaleDateString('pt-BR') || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="CPF"
              value={formValues.cpf || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Estado (UF)"
              value={formValues.uf || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Cidade"
              value={formValues.cidade || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Dados do Responsável</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Nome do Responsável"
              value={formValues.nomeResponsavel || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="RG do Responsável"
              value={formValues.rgResponsavel || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="CPF do Responsável"
              value={formValues.cpfResponsavel || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Telefone do Responsável"
              value={formValues.telefoneResponsavel || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="E-mail do Responsável"
              value={formValues.emailResponsavel || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Dados Educacionais</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Rede de Ensino"
              value={formValues.redeEnsino || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Escola"
              value={formValues.escola || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Polo"
              value={formValues.polo || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Tipo de Deficiência"
              value={formValues.tipoDeficiencia || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Atendimento Especializado"
              value={formValues.atendimentoEdspecializado || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Status"
              value={formValues.status || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Button className="no-print" variant="contained" color="primary" onClick={handlePrint}>
          Imprimir
        </Button>
      </Box>
    </Box>
  );
}

export default FormularioBilingue2024Visualizacao;
