import React, { useEffect, useState } from 'react'
import FormInput from '../../../../../../components/formInput'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import api from '../../../../../../services'
import { toast } from 'react-toastify'
import { cpf } from 'cpf-cnpj-validator'


export default function ProcessosSeletivos() {
  const navigate = useNavigate();
  const [cpfValido, setCpfValido] = useState(false)
  const [exibeFormulario, setExibeFormulario] = useState(0);
  const [contagemRegressivaEncerramento, setContagemRegressivaEncerramento] = useState('');
  const [contagemRegressivaAbertura, setContagemRegressivaAbertura] = useState('');


  setInterval(() => {

    var dataAtual = new Date();
    var time = Date.parse(new Date(2024, 4, 10, 17, 0, 0)) - Date.parse(dataAtual);
    var seconds = Math.floor((time / 1000) % 60);
    var minutes = Math.floor((time / 1000 / 60) % 60);
    var hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    var days = Math.floor(time / (1000 * 60 * 60 * 24));
    var dias = days == 0 ? "" :  `${ days.toString()} Dias -`;
    setContagemRegressivaEncerramento(`${dias} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

    var time = Date.parse(new Date(2024, 4, 3, 9, 0, 0)) - Date.parse(dataAtual);
    var seconds = Math.floor((time / 1000) % 60);
    var minutes = Math.floor((time / 1000 / 60) % 60);
    var hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    var days = Math.floor(time / (1000 * 60 * 60 * 24));
    var dias = days == 0 ? "" :  `${ days.toString()} Dias -`;
    setContagemRegressivaAbertura(`${dias} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

  }, 1000);

  const {
    register,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      cpf: '',
    }
  })

  const handleSearch = async (cpf) => {
    const response = await api.get(`/processoSeletivo/inscricao/cpf?cpf=${cpf}`);
    const id = response?.data?.data.id;

    if (id) {
      navigate('/processos-seletivos/inscricao/' + id)
    } else {
      toast.error('Inscrição não encontrada na base de dados.')
    }
  }

  const validaExibeFormulario = () => {
    var dataAbertura = new Date(2024, 4, 3, 9, 0, 0);
    var dataFechamento = new Date(2024, 4, 10, 17, 0, 0);
    var dataAtual = new Date();
    if (dataAtual >= dataAbertura && dataAtual < dataFechamento)
      setExibeFormulario(1);
    if (dataAtual > dataFechamento)
      setExibeFormulario(2);
  }

  useEffect(() => { //Checando se CPF do aluno é válido
    validaExibeFormulario();

    const cpfWithoutMask = watch('cpf').replaceAll('.', '').replaceAll('-', '');
    const isValidCpf = cpf.isValid(cpfWithoutMask);

    setCpfValido(isValidCpf)
  }, [watch('cpf')])

  return (
    <div
      className="tab-pane fade show active"
      id="listaEspera"
      role="tabpanel"
      aria-labelledby="listaEspera-tab"
    >
      <div className="card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h5>SISTEMA ELETRÔNICO DE PROCESSO SELETIVO DA REDE MUNICIPAL DE ENSINO DE ARARUAMA</h5>
        <h6>ESCOLHA UMA DAS OPÇÕES ABAIXO:</h6>

        {exibeFormulario == 0 ?
          <>
           <div>
                <center>
                  <div style={{ color: '#d2d208', fontSize: '25px', fontWeight: 'normal' }} id="dday">
                  O formulário será disponibilizado em {contagemRegressivaAbertura}
                  </div>
                </center>
              </div>
            <div style={{ border: '1px solid #dee2e6', borderRadius: '5px', display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '12px', margin: '12px 0' }}>
              <h2 style={{ textAlign: 'center', color: 'red' }}>INSCRIÇÕES ABERTAS A PARTIR DO DIA<br></br> 03/05/2024 ÀS 9H</h2>
            </div>
          </>
          : exibeFormulario == 1 ?
            <>
              <div>
                <center>
                  <div style={{ color: '#d2d208', fontSize: '25px', fontWeight: 'normal' }} id="dday">
                  O processo seletivo se encerra em  {contagemRegressivaEncerramento}
                  </div>
                </center>
              </div>
              <div style={{ border: '1px solid #dee2e6', borderRadius: '5px', display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '12px', margin: '12px 0' }}>
                <h3 style={{ color: '#3377c0', fontSize: '35px', alignSelf: 'center' }} >PESQUISAR</h3>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', width: '100%' }}>
                  <div style={{ width: '60%' }} >
                    <FormInput
                      register={register}
                      errors={errors}
                      mask="999.999.999-99"
                      atribute="cpf"
                      style={{ width: '100%' }}
                      required={false}
                      placeholder="CPF DO ALUNO"
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {!cpfValido && watch('cpf') !== '' && (
                        <span className="text-danger">CPF Inválido</span>
                      )}
                    </div>
                  </div>
                  <button
                    disabled={!cpfValido}
                    onClick={() => handleSearch(watch('cpf'))}
                    style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px', width: '30%', height: '43px', alignSelf: 'flex-end' }} >PESQUISAR</button>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', width: '80%', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #dee2e6', padding: '12px', gap: '8px', borderRadius: '5px' }} >
                  <h3 style={{ color: '#3377c0', fontSize: '24px', textAlign: 'center' }} >INSCRIÇÃO ESCOLA BILÍNGUE MUNICIPAL AGRONEGÓCIO E TURISMO RURAL</h3>
                  <button
                    type="button"
                    onClick={() => navigate('/psbilingue/formulario')}
                    style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px' }}
                  >CADASTRO</button>
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #dee2e6', borderRadius: '5px', padding: '12px', gap: '8px' }} >
                  <h3 style={{ color: '#3377c0', fontSize: '24px', textAlign: 'center' }} >INSCRIÇÃO ESCOLA BILÍNGUE MUNICIPAL GETÚLIO VARGAS (GASTRONOMIA E HOTELARIA)</h3>
                  <button onClick={() => navigate('/processo-seletivo/gastronomia')} style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px' }} >CADASTRO</button>
                </div> */}
              </div>
            </> :
            <>
              <div style={{ border: '1px solid #dee2e6', borderRadius: '5px', display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '12px', margin: '12px 0' }}>
                <h2 style={{ textAlign: 'center', color: 'red' }}>INSCRIÇÕES ENCERRADAS</h2>
              </div>
            </>
        }
      </div>
    </div>



  )
}
