export const perguntasIniciaisCreche = [
    {
        id: 1,
        nome: 'Percebe que suas ações têm efeitos nas outras crianças e adultos',
        disciplinaId: 29,
    },
    {
        id: 2,
        nome: 'Percebe as possibilidades e limites do seu corpo nas brincadeiras e interações das quais participa',
        disciplinaId: 29,
    },
    {
        id: 3,
        nome: 'Interage com as crianças da mesma faixa etária e adultos ao explorar matérias, objetos, brinquedos',
        disciplinaId: 29,
    },
    {
        id: 4,
        nome: 'Comunica necessidade, desejos e emoções, utilizando gestos, balbucios, palavras',
        disciplinaId: 29,
    },
    {
        id: 5,
        nome: 'Reconhece as sensações do corpo, em momentos de alimentação, higiene, brincadeira e descanso',
        disciplinaId: 29,
    },
    {
        id: 6,
        nome: 'Constrói formas de interação com crianças da mesma faixa etária e adultos adaptando-se ao convívio social',
        disciplinaId: 29,
    },
    {
        id: 7,
        nome: 'Demonstra atitudes de cuidado e solidariedade na interação com crianças e adultos',
        disciplinaId: 29,
    },
    {
        id: 8,
        nome: 'Demonstra imagem positiva de si, confiança em sua capacidade para enfrentar dificuldades e desafios',
        disciplinaId: 29,
    },
    {
        id: 9,
        nome: 'Compartilha os objetos e os espaços com crianças da mesma faixa etária e adultos',
        disciplinaId: 29,
    },
    {
        id: 10,
        nome: 'Comunica-se com os colegas e os adultos, buscando compreendê-los e fazendo-se compreender',
        disciplinaId: 29,
    },
    {
        id: 11,
        nome: 'Habitua-se a prática de cuidado como corpo, desenvolvendo noções de bem-estar',
        disciplinaId: 29,
    },
    {
        id: 12,
        nome: 'Respeita regras básicas de convívio social nas brincadeiras e interações',
        disciplinaId: 29,
    },
    {
        id: 13,
        nome: 'Valoriza a diversidade ao participar de situações de convívio com diferenças',
        disciplinaId: 29,
    },
    {
        id: 14,
        nome: 'Resolve conflitos nas interações e brincadeiras, com orientação de um adulto',
        disciplinaId: 29,
    },
    {
        id: 15,
        nome: 'Movimenta as partes do corpo para exprimir corporalmente emoções, necessidades e desejos',
        disciplinaId: 30,
    },
    {
        id: 16,
        nome: 'Amplia suas possibilidades de movimento em espaços que possibilitem explorações diferenciadas',
        disciplinaId: 30,
    },
    {
        id: 17,
        nome: 'Experimenta as possibilidades de seu corpo nas brincadeiras e interações em ambientes acolhedores e desafiantes',
        disciplinaId: 30,
    },
    {
        id: 18,
        nome: 'Participa do cuidado do seu corpo e da promoção do seu bem-estar',
        disciplinaId: 30,
    },
    {
        id: 19,
        nome: 'Imita gestos, sonoridade e movimentos de outras crianças, adultos e animais',
        disciplinaId: 30,
    },
    {
        id: 20,
        nome: 'Utiliza os movimentos de preensão, encaixe e lançamento',
        disciplinaId: 30,
    },
    {
        id: 21,
        nome: 'Explora formas de deslocamento no espaço (pular, saltar, dançar), combinando movimentos e seguindo orientações',
        disciplinaId: 30,
    },
    {
        id: 22,
        nome: 'Desenvolve progressivamente as habilidades manuais, adquirindo controle para desenhar, pintar, rasgar, folhear, entre outros',
        disciplinaId: 30,
    },
    {
        id: 23,
        nome: 'Apropria-se de gestos e movimentos de sua cultura no cuidado de si e nos jogos e brincadeiras',
        disciplinaId: 30,
    },
    {
        id: 24,
        nome: 'Desloca seu corpo no espaço, orientando-se por noções como em frente, atrás, embaixo, dentro e fora',
        disciplinaId: 30,
    },
    {
        id: 25,
        nome: 'Faz uso de suas possibilidades corporais, ao se envolver em brincadeiras e atividades',
        disciplinaId: 30,
    },
    {
        id: 26,
        nome: 'Explora formas de deslocamento no espaço (pular, saltar, dançar), combinando movimentos e orientações',
        disciplinaId: 30,
    },
    {
        id: 27,
        nome: 'Demonstra progressiva independência no cuidado com o corpo',
        disciplinaId: 30,
    },
    {
        id: 28,
        nome: 'Desenvolve progressivamente as habilidades manuais, adquirindo controle para desenhar, pintar, rasgar, folhear, entre outros',
        disciplinaId: 30,
    },
    {
        id: 29,
        nome: 'Explora sons produzidos com o próprio corpo e com objetos do ambiente.',
        disciplinaId: 31,
    },
    {
        id: 30,
        nome: 'Traça marcas gráficas, em diferentes suportes, usando instrumentos riscantes e tintas.',
        disciplinaId: 31,
    },
    {
        id: 31,
        nome: 'Explora diferentes fontes sonoras e materiais para acompanhar brincadeiras cantadas, canções, melodias.',
        disciplinaId: 31,
    },
    {
        id: 32,
        nome: 'Utiliza materiais variados com possibilidades de manipulação (argila, massa de modelar) criando objetos tridimensionais.',
        disciplinaId: 31,
    },
    {
        id: 33,
        nome: 'Imita gestos, movimentos, sons, palavras de outras crianças, e adultos, animais, objetos e fenômenos da natureza.',
        disciplinaId: 31,
    },
    {
        id: 34,
        nome: 'Cria sons com materiais, objetos e instrumentos musicais, para acompanhar diversos ritmos de música.',
        disciplinaId: 31,
    },
    {
        id: 35,
        nome: 'Utiliza diferentes materiais, suportes e procedimentos para grafar, explorando cores, texturas, planos, formas e volumes.',
        disciplinaId: 31,
    },
    {
        id: 36,
        nome: 'Utiliza diferentes fontes sonoras disponíveis no ambiente em brincadeiras cantadas, canções, músicas e melodias.',
        disciplinaId: 31,
    },
    {
        id: 37,
        nome: 'Imita e cria movimentos próprios, em danças, cenas de teatro, narrativas e músicas.',
        disciplinaId: 31,
    },
    {
        id: 38,
        nome: 'Reconhece quando é chamado pelo seu nome e reconhece o nome das pessoas com quem convive.',
        disciplinaId: 32,
    },
    {
        id: 39,
        nome: 'Demonstra interesse ao ouvir a leitura de poemas e apresentação de músicas.',
        disciplinaId: 32,
    },
    {
        id: 40,
        nome: 'Demonstra interesse ao ouvir histórias lidas ou contadas, observando ilustrações e os movimentos de leitura do adulto-leitor.',
        disciplinaId: 32,
    },
    {
        id: 41,
        nome: 'Reconhece elementos das ilustrações de histórias, apontando-os, a pedido do adulto-leitor.',
        disciplinaId: 32,
    },
    {
        id: 42,
        nome: 'Imita as entonações e gestos realizados pelos adultos, ao ler histórias e cantar.',
        disciplinaId: 32,
    },
    {
        id: 43,
        nome: 'Comunica-se com outras pessoas usando movimentos, gestos, balbucios, fala e outras formas de expressões.',
        disciplinaId: 32,
    },
    {
        id: 44,
        nome: 'Conhece e manipula materiais impressos e audiovisuais em diferentes portadores.',
        disciplinaId: 32,
    },
    {
        id: 45,
        nome: 'Demonstra familiaridade no contato com diferentes gêneros textuais (poemas, fábulas, contos, receitas, quadrinhos, anúncios).',
        disciplinaId: 32,
    },
    {
        id: 46,
        nome: 'Demonstra familiaridade no contato com diferentes instrumentos e suportes de escrita.',
        disciplinaId: 32,
    },
    {
        id: 47,
        nome: 'Dialoga com adultos, crianças, expressando seus desejos, necessidades, sentimentos e opiniões.',
        disciplinaId: 32,
    },
    {
        id: 48,
        nome: 'Identifica e cria diferentes sons e reconhece rimas e aliterações com cantigas de roda e textos poéticos.',
        disciplinaId: 32,
    },
    {
        id: 49,
        nome: 'Demonstra interesse e atenção no ouvir a leitura de histórias e outros textos, diferenciando escrita de ilustração, e acompanhando com orientação a direção da leitura.',
        disciplinaId: 32,
    },
    {
        id: 50,
        nome: 'Formula e responde perguntas sobre fatos na história narrada, identificando cenários, personagens e principais acontecimentos.',
        disciplinaId: 32,
    },
    {
        id: 51,
        nome: 'Relata experiências e fatos da história narrada, identificando cenário, personagens.',
        disciplinaId: 32,
    },
    {
        id: 52,
        nome: 'Cria e conta história oralmente, com base em linguagens ou temas sugeridos.',
        disciplinaId: 32,
    },
    {
        id: 53,
        nome: 'Reconhece e manipula materiais impressos e audiovisuais em diferentes portadores.',
        disciplinaId: 32,
    },
    {
        id: 54,
        nome: 'Demonstra familiaridade no contato com suportes de escrita para desenhar, traçar letras e outros sinais gráficos.',
        disciplinaId: 32,
    },
    {
        id: 55,
        nome: 'Explora as prioridades de objetos e materiais (odor, cor, sabor, temperatura).',
        disciplinaId: 33,
    },
    {
        id: 56,
        nome: 'Explora relações de causa e efeito (transbordar, tingir, misturas, mover, remover).',
        disciplinaId: 33,
    },
    {
        id: 57,
        nome: 'Explora o ambiente pela ação e observação, manipulação e experimentação.',
        disciplinaId: 33,
    },
    {
        id: 58,
        nome: 'Manipula, experimenta, arruma e explora o espaço por meio de experiências de deslocamento de si e dos objetos.',
        disciplinaId: 33,
    },
    {
        id: 59,
        nome: 'Manipula objetos e variados materiais para comparar as diferenças e semelhanças.',
        disciplinaId: 33,
    },
    {
        id: 60,
        nome: 'Vivencia diferentes ritmos, velocidades, em interações e brincadeiras, com interesse.',
        disciplinaId: 33,
    },
    {
        id: 61,
        nome: 'Explora e descreve semelhanças e diferenças entre as características e propriedades dos objetos (texturas, massa, tamanho).',
        disciplinaId: 33,
    },
    {
        id: 62,
        nome: 'Observa, relata e descreve incidentes do cotidiano e fenômenos naturais (luz solar, vento, chuva, etc).',
        disciplinaId: 33,
    },
    {
        id: 63,
        nome: 'Compartilha com outras crianças, situações de cuidado de plantas e animais nos espaços da instituição e fora dela.',
        disciplinaId: 33,
    },
    {
        id: 64,
        nome: 'Identifica relações espaciais (dentro/fora, em cima/embaixo, entre e do lado) e temporais (antes, durante e depois).',
        disciplinaId: 33,
    },
    {
        id: 65,
        nome: 'Classifica objetos básicos considerando determinado atributo (tamanho, peso, cor, forma, etc.).',
        disciplinaId: 33,
    },
    {
        id: 66,
        nome: 'Utiliza conceitos básicos de tempo (agora, hoje, amanhã, lento, rápido, depressa, devagar).',
        disciplinaId: 33,
    },
    {
        id: 67,
        nome: 'Conta oralmente objetos, pessoas, livros, etc.',
        disciplinaId: 33,
    },
    {
        id: 68,
        nome: 'Registra com números a quantidade de crianças (meninas e meninos, presentes, ausentes) e a quantidade de objetos da mesma natureza.',
        disciplinaId: 33,
    },
];