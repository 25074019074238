import api from "../..";

async function postListaEspera(data) {
  return await api
    .post('listaespera', data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function listarMunicipios(tipo = 0) {
return await api
    .get(`processoSeletivo/unidade/municipios?tipo=${tipo}`)
    .then(async (res) => {
    const { data } = res;
    return data;
    });
};

async function listarPolos() {
    return await api
    .get(`processoSeletivo/unidade/polos`)
    .then(async (res) => {
        const { data } = res;
        return data;
    });
};

async function postInscricao(data) {
    return await api
        .post('processoSeletivo/inscricao', data)
        .then(async (res) => {
        console.log(res)
        const data = res.data;
    
        return data;
        });
    };

export {
  postListaEspera,
    listarMunicipios,
    listarPolos,
    postInscricao
}