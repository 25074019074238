import urlsBilingue from './UrlsBilingue';

// Import Material UI icons
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import FolderIcon from '@mui/icons-material/Folder';

const linksBilingue = () => {
    return {
        bilingue: {
            id: 'bilingue',
            text: 'BILINGUE',
            type: 'title',
            visible: true,
            itens: {
                dashboard: {
                    id: 'dashboard',
                    text: 'DASHBOARD',
                    path: urlsBilingue.dashboard,
                    icon: 'dashboard',
                    visible: true,
                },
                inscricoes: {
                    id: 'inscricoes',
                    text: 'INSCRIÇÕES',
                    path: urlsBilingue.inscricoes,
                    icon: 'group',
                    visible: true,
                },
            }
        },
        formularios: {
            id: 'formularios',
            text: 'FORMULÁRIOS',
            type: 'title',
            visible: true,
            itens: {
                formulario:{
                    id: 'formulario',
                    text: 'FORMULÁRIO',
                    iconName: '',
                    icon: 'folder',  // Icon for Formulário
                    visible: true,
                    path: urlsBilingue.formulario,
                },
                militar: {
                    id: 'militar',
                    text: 'MILITAR',
                    path: urlsBilingue.formularioMilitar,
                    iconName: '',
                    icon: 'military_tech',  // Icon for militar
                    visible: true,
                },
                formularioGastronomiaHotelaria: {
                    id: 'gastronomia-hotelaria',
                    text: 'GASTRONOMIA E HOTELARIA',
                    path: urlsBilingue.formularioGastronomiaHotelaria,
                    iconName: '',
                    icon: 'restaurant',  // Icon for Gastronomia
                    visible: true,
                },
                formularioAgronegocioTurismo: {
                    id: 'agronegocio-turismo',
                    text: 'AGRONEGÓCIO E TURISMO',
                    path: urlsBilingue.formularioAgronegocioTurismo,
                    iconName: '',
                    icon: 'agriculture',  // Icon for Agronegócio
                    visible: true,
                },
            }
        }
    };
};

export default linksBilingue;
