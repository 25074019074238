export const escolasMunicipais = [
    { label: "ESCOLA ESTADUAL MUNICIPALIZADA PARATI" },
    { label: "ESCOLA MUNICIPAL AGOSTINHO FRANCESCHI" },
    { label: "ESCOLA MUNICIPAL ANDERSON DOMINGUES DE OLIVEIRA" },
    { label: "ESCOLA MUNICIPAL ANDRÉ GOMES DOS SANTOS" },
    { label: "ESCOLA MUNICIPAL ANTONIO ANDRADE MÜLLER" },
    { label: "ESCOLA MUNICIPAL BRUNNO BARRETO NAMETALA" },
    { label: "ESCOLA MUNICIPAL CELINA MESQUITA PEDROSA" },
    { label: "ESCOLA MUNICIPAL DR. JOÃO VASCONCELLOS" },
    { label: "ESCOLA MUNICIPAL ECOLÓGICA PROFESSOR FÁBIO SIQUEIRA" },
    { label: "ESCOLA MUNICIPAL FAUSTINA SILVA CARVALHO" },
    { label: "ESCOLA MUNICIPAL FRANCISCO DOMINGUES NETO" },
    { label: "ESCOLA MUNICIPAL HONORINO COUTINHO" },
    { label: "ESCOLA MUNICIPAL JERÔNIMO CARLOS NASCIMENTO" },
    { label: "ESCOLA MUNICIPAL JOÃO AUGUSTO CHAVES" },
    { label: "ESCOLA MUNICIPAL JOÃO BRITO DE SOUZA" },
    { label: "ESCOLA MUNICIPAL JOAQUINA OLIVEIRA RANGEL" },
    { label: "ESCOLA MUNICIPAL JOSÉ CORRÊA DA FONSECA" },
    { label: "ESCOLA MUNICIPAL MARGARIDA TRINDADE DE DEUS" },
    { label: "ESCOLA MUNICIPAL MENINO ROBSON JUNIOR" },
    { label: "ESCOLA MUNICIPAL NEDIR PAULO BARROSO DA ROSA" },
    { label: "ESCOLA MUNICIPAL PASTOR ALCEBÍADES FERREIRA DE MENDONÇA" },
    { label: "ESCOLA MUNICIPAL PREFEITO ALTEVIR VIEIRA PINTO BARRETO" },
    { label: "ESCOLA MUNICIPAL PROF. CARLOS LEAL" },
    { label: "ESCOLA MUNICIPAL PROFª HEGLÁUCIA MARIA DE MELLO MATTA" },
    { label: "ESCOLA MUNICIPAL PROFESSOR DARCY RIBEIRO" },
    { label: "ESCOLA MUNICIPAL PROFESSOR PEDRO PAULO DE BRAGANÇA PIMENTEL" },
    { label: "ESCOLA MUNICIPAL PROFESSOR RAYMUNDO MAGNO CAMARÃO" },
    { label: "ESCOLA MUNICIPAL PROFESSORA NAIR VALLADARES" },
    { label: "ESCOLA MUNICIPAL SARA URRUTIA BAPTISTA" },
    { label: "ESCOLA MUNICIPAL SINVAL PINTO DE FIGUEIREDO" },
    { label: "ESCOLA MUNICIPAL TONINHO SENRA" },
    { label: "ESCOLA MUNICIPAL VER. ALCEBÍADES CARVALHO DAS FLORES" },
    { label: "ESCOLA MUNICIPAL VEREADOR EDEMUNDO PEREIRA DE SÁ CARVALHO" },
    { label: "ESCOLA MUNICIPAL VEREADOR MOYSÉS RAMALHO" },
    { label: "PRAÇA ESCOLA ECOLÓGICA MUNICIPAL DR. FERNANDO CARVALHO" },
    { label: "PRAÇA ESCOLA IGUABINHA - MARCOS HERON CORRÊA" },
    { label: "PRAÇA ESCOLA ITATIQUARA - PREF. AFRÂNIO VALADARES" },
    { label: "PRAÇA ESCOLA PRAIA SECA - COMANDANTE SÉRGIO RIBEIRO" }
  ];
  
  export const escolasPrivadas = [
    { label: "CENTRO EDUC. CAMARGOS" },
    { label: "CENTRO EDUC. CERQUEIRA QUINTANILHA (SEMEANDO SABER)" },
    { label: "CENTRO EDUC. EXIMIUS (ZERO HUM)" },
    { label: "CENTRO EDUC. FUTURA" },
    { label: "CENTRO EDUC. MARGARIDA (CEM)" },
    { label: "CENTRO EDUC. MILTON MOTTA" },
    { label: "CENTRO EDUC. MONTEIRO CORREA" },
    { label: "CENTRO EDUC. NILDA MENDES (CENIM)" },
    { label: "CENTRO EDUC. OS SABIDINHOS DE ARARUAMA" },
    { label: "CENTRO EDUC. PRÉ UNIVERSITÁRIO" },
    { label: "CENTRO EDUC. RENASCER" },
    { label: "CENTRO EDUC. RODRIGUES MELLO (ABELHINHA FELIZ)" },
    { label: "CENTRO EDUC. TERRA NOVA DE ARARUAMA LTDA" },
    { label: "COLÉGIO ARARUAMA" },
    { label: "COLEGIO CURSOS CEJAN LTDA - ME" },
    { label: "COLÉGIO FUTURO" },
    { label: "COLEGIO LÓGICA" },
    { label: "COLÉGIO PROF. FERNANDO MOREIRA CALDAS" },
    { label: "COLEGIO SANTANNA" },
    { label: "OUTROS (TER A POSSIBILIDADE DE DIGITAR)" }
  ];

  export const polosProva = [
    { label: "ESCOLA BILÍNGUE M. SUELI AMARAL – RUA BOLÍVIA, S/N°. PARQUE HOTEL. (1° DISTRITO)" },
    { label: "ESCOLA MUNICIPAL PROFª. NAIR VALLADARES – RUA PREFEITO MÁRIO ALVES, N°99. PARQUE MATARUNA. (1° DISTRITO)" },
    { label: "PRAÇA ESCOLA PREF. AFRÂNIO VALLADARES – ESTRADA DE MORRO GRANDE, S/N°, LT 01/02 -QD F. ITATIQUARA (2° DISTRITO)" },
    { label: "COLÉGIO MUNICIPAL PROF. PEDRO PAULO DE B. PIMENTEL – RUA TEREZINHA, S/N°. LOT. NOVA SÃO VICENTE. SÃO VICENTE. (3° DISTRITO)" },
    { label: "PRAÇA ESCOLA COM. SÉRGIO RIBEIRO DE VASCONCELLOS – RUA HERON DOMINGUES, S/N°. PRAIA SECA. (4° DISTRITO)" },
    { label: "ESCOLA MUNICIPAL ANDRÉ GOMES DOS SANTOS – RUA NOVO HORIZONTE, S/N°. BANANEIRAS. (5° DISTRITO)" }
  ];

  export const deficiencias = [
    { label: "DEFICIÊNCIA FÍSICA" },
    { label: "DEFICIÊNCIA INTELECTUAL/SÍNDROME DE DOWN" },
    { label: "SUPERDOTAÇÃO/ALTAS HABILIDADES" },
    { label: "TDA/TDAH/DISLEXIA/DISORTOGRAFIA" },
    { label: "TRANSTORNO DO ESPECTRO AUTISTA" }
  ];
  
  export const tipoAtendimentoEspecilizado = [
    { label: "CARACTERES AMPLIADOS" },
    { label: "LEDOR" },
    { label: "PROFISSIONAL DE APOIO/AUXILIAR" },
    { label: "SALA DE FÁCIL ACESSO" },
    { label: "NÃO NECESSITO" }
  ];
  