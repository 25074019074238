import Breadcrumb from "../../../../components/breadcrumb";
import BaseServidores from "./baseServidores";
import { getDashboardProcessoSeletivoServidores } from "../../../../services/dashboard";
import { useQuery } from 'react-query';
import Spinner from "../../../../components/spinner";

const HomeServidores = () => {
  const { data, isLoading } = useQuery('getDashboardProcessoSeletivoServidores', getDashboardProcessoSeletivoServidores, {
    retry: 3
  });

  return (
    <BaseServidores>
      <Breadcrumb title={'Informações Gerais'} itens={['Processo Seletivo Servidores', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
          {/* <h4>Dados Gerais Inscrições</h4> */}
            <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">AGENTE DE SERVIÇOS GERAIS</span>
                        <span className="widget-stats-amount">{data?.cargo1}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">AUXILIAR DE DISCIPLINA</span>
                        <span className="widget-stats-amount">{data?.cargo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>




            
            

            <div className="row">
            <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">MERENDEIRA</span>
                        <span className="widget-stats-amount">{data?.cargo3}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">MONITOR DE TRANSPORTE ESCOLAR</span>
                        <span className="widget-stats-amount">{data?.cargo4}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
              </div>

              <div className="row">
            <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">MOTORISTA D</span>
                        <span className="widget-stats-amount">{data?.cargo5}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">NUTRICIONISTA</span>
                        <span className="widget-stats-amount">{data?.cargo6}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
              </div>

              <div className="row">
            <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">OFICIAL ADMINISTRATIVO</span>
                        <span className="widget-stats-amount">{data?.cargo7}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">ORIENTADOR EDUCACIONAL</span>
                        <span className="widget-stats-amount">{data?.cargo8}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
              </div>

              <div className="row">
            <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">ORIENTADOR PEDAGÓGICO</span>
                        <span className="widget-stats-amount">{data?.cargo9}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">PROFESSOR I</span>
                        <span className="widget-stats-amount">{data?.cargo10}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
              </div>

              <div className="row">
            <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">PROFESSOR II</span>
                        <span className="widget-stats-amount">{data?.cargo11}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">SECRETÁRIO ESCOLAR</span>
                        <span className="widget-stats-amount">{data?.cargo12}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
              </div>

              <div className="row">
            <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">VIGIA</span>
                        <span className="widget-stats-amount">{data?.cargo13}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>            
              
              </div>

              

              

              
            
          </>
        )}
    </BaseServidores>
  )
};

export default HomeServidores;

