import React, { useEffect, useState } from 'react';
import { postNotaLinhaRiap } from '../../../services/ficha-avaliacao';
import { toast } from 'react-toastify';
import Alert from '@mui/material/Alert';
import { listarLancamentosRiapAlunoId } from '../../../services/ficha-avaliacao';
import { perguntasIniciaisCreche } from './perguntasCreche';
import { perguntasIniciaisPre } from './perguntasPre';

const DisciplinasInfantil = ({ disciplina, ciclo, turma, aluno, escolaridade }) => {
    const [perguntas, setPerguntas] = useState([]);
    const [respostas, setRespostas] = useState([]);
    const opcoesResposta = ['S', 'N', 'EP', 'NI'];

    useEffect(() => {
        const fetchPerguntas = () => {
            let perguntasIniciais = [];
            if ([12, 13, 14, 15].includes(escolaridade)) {
                perguntasIniciais = perguntasIniciaisCreche;
            }
            if ([24, 25].includes(escolaridade)) {
                perguntasIniciais = perguntasIniciaisPre;
            }
            setPerguntas(perguntasIniciais);
        };

        fetchPerguntas();
    }, [escolaridade]);

    useEffect(() => {
        const fetchRespostas = async () => {
            try {
                const respostas = await listarLancamentosRiapAlunoId(aluno);
                setRespostas(respostas);
            } catch (error) {
                toast.error('Não foi possível carregar as respostas.');
                console.error('Erro ao carregar as respostas:', error);
            }
        };

        fetchRespostas();
    }, [aluno]);

    useEffect(() => {
        if (respostas.length > 0 && perguntas.length > 0) {
            const updatedPerguntas = perguntas.map((pergunta) => {
                const resposta = respostas.find((res) => res.perguntaId === pergunta.id && res.trimestreId === parseInt(ciclo));
                return { ...pergunta, resposta: resposta ? resposta.nota : '' };
            });
            setPerguntas(updatedPerguntas);
        }
    }, [respostas]);

    async function handleBlur(row, e) {

        if (!e.target.value) {
            return
        }
        let data = {
            alunoId: aluno,
            disciplinaId: disciplina,
            trimestreId: ciclo,
            turmaId: turma,
            nota: e.target.value.toUpperCase(),
            pergunta: row.id
        };

        if (!opcoesResposta.includes(data.nota)) {
            toast.error('Valor informado inválido!');
            return;
        }

        const response = await postNotaLinhaRiap(data);

        if (response?.statusCode === undefined) {
            return toast.error('Erro ao lançar notas!');
        }
        if (response.statusCode === 200) {
            toast.success('Notas lançadas com sucesso!');
        } else {
            toast.error('Erro ao lançar notas!');
        }
    }

    const handleChange = (row, e) => {
        const { value } = e.target;
        handleBlur(row, e);
        setPerguntas((prevPerguntas) =>
            prevPerguntas.map((pergunta) =>
                pergunta.id === row.id ? { ...pergunta, resposta: value } : pergunta
            )
        );
    };

    return (
        <div className="table-responsive">
            <Alert variant="filled" severity="info" style={{ marginBottom: 20 }}>
                Opções disponíveis para lançamento: <b>S - SIM | N - NÃO | EP - EM PROCESSO | NI - NÃO INTRODUZIDO</b>
            </Alert>
            <table className="table table-striped table-hover w-100">
                <thead>
                    <tr className="text-center">
                        <th style={{ display: 'none' }} className="text-center">ID</th>
                        <th className="text-center">PERGUNTA</th>
                        <th className="text-center">RESULTADO</th>
                    </tr>
                </thead>
                <tbody>
                    {perguntas.length > 0 && perguntas
                        .filter((row) => row.disciplinaId === disciplina)
                        .map((row) => (
                            <tr key={row.id} className="text-center">
                                <td style={{ display: 'none' }}>{row.id}</td>
                                <td>{row.nome}</td>
                                <td>
                                    <select
                                        className="form-control"
                                        aria-labelledby="aria-label"
                                        // id={'nota' + row.id}
                                        // name={'nota' + row.id}
                                        value={row.resposta || ''}
                                        // onBlur={(e) => handleBlur(row, e)}
                                        onChange={(e) => handleChange(row, e)}
                                    >
                                        <option value=""></option>
                                        {opcoesResposta.map((opcao) => (
                                            <option key={opcao} value={opcao}>{opcao}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default DisciplinasInfantil;
