import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import FormInput from '../../../../../../../components/formInput'
import { getCidades, getEstados } from '../../../../../../../services/endereco'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { listarPolos, listarMunicipios, postInscricao } from '../../../../../../../services/processos/bilingue'
import { cpf } from 'cpf-cnpj-validator'
import urlsBilingue from '../../../../private/urlsBilingue'
import api from '../../../../../../../services'
import moment from "moment";

export default function ProcessoSeletivoCivico() {
  const navigate = useNavigate();

  const [stateSelected, setStateSelected] = useState();
  const unidade = useState(0)
  const [tipo, setTipo] = useState(0)
  const [cpfAlunoValido, setCpfAlunoValido] = useState(false);
  const [cpfResponsavelValido, setCpfResponsavelValido] = useState(false);
  const [cpfIsEqual, setCpfIsEqual] = useState(false)
  const [dataNascimentoValid, setDataNascimentoValid] = useState(true)
  const [exibeBotaoSalvar, setExibeBotaoSalvar] = useState(false)
  const [dataNascimentoEarlier, setDataNascimentoEarlier] = useState(true)
  const [dataNascimentoFilhoNaoFez9Anos, setDataNascimentoFilhoNaoFez9Anos] = useState(true)
  const [nomeValid, setNomeValid] = useState(false)
  const [nomeResponsavel, setNomeResponsavel] = useState(false)
  const [rgResponsavel, setRgResponsavel] = useState(false)
  const [cpfResponsavel, setCpfResponsavel] = useState(false)

  const { data: polos} = useQuery(
    'listarPolos',
    () => (unidade !== null ? listarPolos() : null),
    {
      retry: 3,
    }
  )

  const { data: estadosData } = useQuery('getEstados', () => getEstados(), {
    enabled: true,
    retry: true
  })

  const { data: cidadesData, refetch: cidadesRefetch } = useQuery('getCidades', () => getCidades(stateSelected), {
    enabled: false,
    retry: true
  })

  const validaExibeSalvar = () => {
    var dataAbertura = new Date(2024, 4, 3, 9, 0, 0);
    var dataFechamento = new Date(2024, 4, 10, 17, 0, 0);
    var dataAtual = new Date();
    if (dataAtual >= dataAbertura && dataAtual < dataFechamento)
    setExibeBotaoSalvar(true);
  }

  useEffect(() => {
    validaExibeSalvar();
  })

  const { mutate: mutatePostForm } = useMutation(postInscricao, {
    onSuccess: data => {
      if (data == undefined || data == false) {
         toast.error('Ocorreu um erro no envio, favor tentar novamente mais tarde.')
      }
      if (data != false && data != undefined) {
        toast.success('Inscrição enviada com sucesso.');
        navigate(urlsBilingue.visualizar + data.data.id)
      }


    },
    onError: data => {
      toast.error('Ocorreu um erro no envio, favor tentar novamente mais tarde.')
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      nome: '',
      cpf: '',
      dataNascimento: '',
      estado: '',
      cidade: '',
      escolaOrigemId: 1,
      unidadeId: 0,
      responsavelNome: '',
      responsavelRg: '',
      responsavelCpf: '',
      responsavelNascimento: '',
      responsavelTelefone: '',
      poloAplicacaoProva: '',
      deficiente: '',
      deficiencia: '',
      tipoDeVaga: '',
      necessitaAtendimentoEspecializado: false,
      tipoDeAtendimento: '',
      tipo: 2,
      unidadeOutros: ''
    }
  })

  function removeEmpty(obj) {
    for (const key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }
    return obj;
  }

  // function handleErrorWithoutAttachment() { 
  //   toast.error('O envio do anexo é obrigatório.')
  // } 

  // function handleErrorAttachmentNotPDF() { 
  //   toast.error('O anexo deve ser no formato PDF.')
  // } 
  
  const onSubmit = async data => {

    // let anexoId = '';
    // console.log(data)
    // const {anexo } = data;

    // console.log(anexo)

    // if (data?.deficiente == 1){
    //   if (anexo[0] === undefined){
    //     return handleErrorWithoutAttachment()
    //   }

    


    //   if (anexo[0]) {
    //     const file = anexo[0];

    //     if(file?.size / 1000000 > 5){
    //       return toast.error('Erro no envio do arquivo, arquivo muito grande, máximo permitido 5MB.')
    //     }

    //     if(file?.type != 'application/pdf'){
    //       return handleErrorAttachmentNotPDF() 
    //     }

    //     const formData = new FormData();
    //     formData.append('arquivo', file);

    //     try {
    //       const sendFile = await api.post('/arquivo/processo-seletivo', formData);

    //       anexoId = sendFile.data.data.id;
    //     } catch (err) {
    //       return toast.error('Erro no envio do arquivo, tente novamente mais tarde.')
    //     }

    //   }
    // }

    if (data) {

      if(dataNascimentoFilhoNaoFez9Anos){
        return toast.error('Aluno não possui idade necessária para realizar o cadastro, que são 9 anos completos na data de hoje.')
      }

      if (!nomeValid || !dataNascimentoValid){
        return toast.error('Todos os campos obrigatórios devem ser preenchidos.')
      }
      if(data?.unidadeId == '57' && data?.unidadeOutros == ''){
        return toast.error('Necessário preencher o nome da escola de origem.')
      }
      // if(data?.deficiente == 1 && (anexoId == null || anexoId == '')){
      //   return handleErrorWithoutAttachment()
      // }
      else{
      const request = {
        ...data,
        escolaOrigemId: parseInt(data?.escolaOrigemId),
        unidadeId: parseInt(data?.unidadeId),
        deficiente: Boolean(data?.deficiente),
        deficiencia: data?.deficiente === 1 ? parseInt(data?.deficiencia) : null,
        tipoDeVaga: data?.deficiente === 1 ? parseInt(data?.tipoDeVaga) : null,
        necessitaAtendimentoEspecializado: data?.deficiente === 1 ? Boolean(data?.necessitaAtendimentoEspecializado) : null,
        tipoDeAtendimento: data?.deficiente === 1 ? parseInt(data?.tipoDeAtendimento) : null,
        poloAplicacaoProva: parseInt(data?.poloAplicacaoProva),
        // anexoId: data?.deficiente == true ? anexoId : null,
        escolaOrigemId: 1
      }
      mutatePostForm(removeEmpty(request))
    }
    }
  }

  function yearBetweenDates(date1, date2) {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 *24 * 365)
    );
  };

  const {
    data: municipios,
  } = useQuery(
    'listarMunicipios',
    () => (unidade !== null ? listarMunicipios(tipo) : null),
    {
      retry: 1,
    }
  )


  useEffect(() => {
    if (estadosData?.length > 0) {
      var estadoSelecionado = estadosData?.find((estado) => estado.sigla === getValues('estado'))
      setStateSelected(estadoSelecionado?.id)
    }
  }, [watch('estado')])

  useEffect(() => {
    if (stateSelected !== null && stateSelected !== undefined && stateSelected !== 'SELECIONE') {
      cidadesRefetch()
    }
  }, [stateSelected])


  useEffect(() => {
    if (watch('deficiente') === 0 || (watch('deficiente') !== 1 && watch('deficiente') !== 0)) {
      setValue('deficiencia', null);
      setValue('tipoDeVaga', null);
      setValue('necessitaAtendimentoEspecializado', null);
      setValue('tipoDeAtendimento', null)
    }
    if (watch('necessitaAtendimentoEspecializado') === 0 || (watch('necessitaAtendimentoEspecializado') !== 1 && watch('necessitaAtendimentoEspecializado') !== 0)) {
      setValue('tipoDeAtendimento', null)
    }
  }, [watch('deficiente'), watch('necessitaAtendimentoEspecializado')])

  useEffect(() => { //Checando se CPF do aluno é válido
    const cpfWithoutMask = watch('cpf').replaceAll('.', '').replaceAll('-', '');
    const isValidCpf = cpf.isValid(cpfWithoutMask);

    setCpfAlunoValido(isValidCpf)
    setValue('cpf', cpf.format(cpfWithoutMask))
  }, [watch('cpf')])

  useEffect(() => { //Checando se CPF do responsável é válido
    const cpfWithoutMask = watch('responsavelCpf').replaceAll('.', '').replaceAll('-', '');
    const isValidCpf = cpf.isValid(cpfWithoutMask);

    setCpfResponsavelValido(isValidCpf)
    setValue('responsavelCpf', cpf.format(cpfWithoutMask))
  }, [watch('responsavelCpf')])

  useEffect(() => { //Checando se os CPFs são iguais
    if ((watch('cpf') === watch('responsavelCpf')) && watch('cpf').length === 14 && watch('responsavelCpf').length === 14) {
      setCpfIsEqual(true);
    } else {
      setCpfIsEqual(false);
    }
  }, [watch('cpf'), watch('responsavelCpf')])

  useEffect(() => {
    if ((watch('nome').length > 0)) {
      setNomeValid(true);
    } else if (watch('nome').length == 0) {
      setNomeValid(false);
    }
    if ((watch('dataNascimento').length > 0)) {
      setDataNascimentoValid(true);
      let nascimento = watch('dataNascimento')
      let hoje = moment().format("YYYY-MM-DD")
      if(nascimento.substring(0, 4) > 2014){
        setDataNascimentoEarlier(false)
      }
      let anoCompletos = yearBetweenDates(nascimento, hoje)
      if(anoCompletos < 9){
        setDataNascimentoFilhoNaoFez9Anos(true)
      }
      else{
        setDataNascimentoEarlier(true)
        setDataNascimentoFilhoNaoFez9Anos(false)
      }

    } else if (watch('dataNascimento').length == 0) {
      setDataNascimentoValid(false);
    }
    if ((watch('responsavelNome').length > 0)) {
      setNomeResponsavel(true);
    } else if (watch('responsavelNome').length == 0) {
      setNomeResponsavel(false);
    }
    if ((watch('responsavelRg').length > 0)) {
      setRgResponsavel(true);
    } else if (watch('responsavelRg').length == 0) {
      setRgResponsavel(false);
    }
    if ((watch('responsavelRg').length > 0)) {
      setCpfResponsavel(true);
    } else if (watch('responsavelRg').length == 0) {
      setCpfResponsavel(false);
    }
  }, [watch('nome'), watch('dataNascimento')])

  return (
    <div
      className="tab-pane fade show active"
      id="listaEspera"
      role="tabpanel"
      aria-labelledby="listaEspera-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body">
        <div className="row">
          <h4 className='text-dark'>Aluno</h4>
          <hr style={{ marginBottom: '30px' }} />
          <div className="col-md-8">
            <FormInput
              register={register}
              errors={errors}
              // onChange={async (e) => {
              //   await setNome(e.target.value);
              // }}
              atribute="nome"
              label="Nome Completo"
            />
             {/* {!nomeValid &&   (
                <span className="text-danger">Campo obrigatório</span>
              )} */}
          </div>
          

          <div className="col-md-2">
            <FormInput
              register={register}
              errors={errors}
              type="date"
              atribute="dataNascimento"
              label="Data de Nascimento"
            />
             {/* {!dataNascimentoValid && (
                <span className="text-danger">Campo obrigatório</span>
              )} */}
              {!dataNascimentoEarlier && (
                <span className="text-danger">Aceitos alunos até 2014</span>
              )}
              {/* {dataNascimentoFilhoNaoFez9Anos && (
                <span className="text-danger">A criança precisa ter no mínimo 9 anos completos para realizar a inscrição</span>
              )} */}
            
          </div>

          <div className="col-md-2">
            <FormInput
              register={register}
              errors={errors}
              mask="999.999.999-99"
              atribute="cpf"
              label="CPF"
            />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {!cpfAlunoValido && watch('cpf') !== '' && (
                  <span className="text-danger">CPF Inválido</span>
                )}
                {cpfIsEqual && (
                  <span className="text-danger">CPF não pode ser igual ao do responsável</span>
                )}
              </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="estado">UF<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select
                className="form-select"
                id="estado"
                {...register('estado', { required: true })}
              >
                <option></option>
                {estadosData?.map(estados => (
                  <option key={estados.id} value={estados.sigla}>
                    {estados.sigla}
                  </option>
                ))}
              </select>
              {errors?.estado?.type === 'required' && (
                <span className="text-danger">Campo obrigatório</span>
              )}
            </div>
          </div>

          <div className="col-9">

            <div className="form-group">
              <label htmlFor="cidade">Cidade<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select
                className="form-select"
                id="cidade"
                style={{ maxHeight: '100px', overflow: 'auto' }}
                {...register('cidade', { required: true })}
              >
                <option></option>
                {cidadesData?.map(cidades => (
                  <option key={cidades.id} value={cidades.nome}>
                    {cidades.nome}
                  </option>
                ))}
              </select>
              {errors?.cidade?.type === 'required' && (
                <span className="text-danger">Campo obrigatório</span>
              )}
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
          
        

          <div className='col-md-2'>
            <div className="form-group">
              <label htmlFor="deficiente">Deficiente?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="deficiente" {...register("deficiente", { required: true, valueAsNumber: true })}>
                <option value=''></option>
                <option value={1}>SIM</option>
                <option value={0}>NÃO</option>
              </select>
              {errors?.deficiente?.type === 'required' && (
                <span className="text-danger">Campo obrigatório</span>
              )}
            </div>
          </div>

          {
            watch('deficiente') === 1  && (
              
              <>
                <div className='col-md-3'>
            <div className="form-group">
                    <label htmlFor="deficiencia">Deficiência<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                    <select className="form-select" id="deficiencia" {...register("deficiencia", { required: watch('deficiente') === 1 ? true : false, valueAsNumber: true })}>
                      <option value=''>SELECIONE</option>
                <option value={1}>DEFICIÊNCIA FÍSICA</option>
                <option value={2}>DEFICIÊNCIA INTELECTUAL / SÍNDROME DE DOWN</option>
                <option value={5}>SUPERDOTAÇÃO / ALTAS HABILIDADES</option>
                <option value={3}>TDA/ TDAH/ DISLEXIA/ DISORTOGRAFIA</option>
                <option value={4}>TRANSTORNO DO ESPECTRO AUTISTA</option>
              </select>
                    {errors?.deficiencia?.type === 'required' && (
                <span className="text-danger">Campo obrigatório</span>
              )}
            </div>
          </div>

          <div className='col-md-2'>
            <div className="form-group">
                    <label htmlFor="tipoDeVaga">Tipo de Vaga<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                    <select className="form-select" id="tipoDeVaga" {...register("tipoDeVaga", { required: watch('deficiente') === 1 ? true : false, valueAsNumber: true })}>
                      <option value=''>SELECIONE</option>
                <option value={1}>AMPLA CONCORRÊNCIA</option>
                <option value={2}>PCD - PESSOA COM DEFICIÊNCIA</option>
              </select>
                    {errors?.tipoDeVaga?.type === 'required' && (
                <span className="text-danger">Campo obrigatório</span>
              )}
            </div>
          </div>

          <div className='col-md-3'>
            <div className="form-group">
                    <label htmlFor="necessitaAtendimentoEspecializado">Necessita Atendimento Especializado?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                    <select className="form-select" id="necessitaAtendimentoEspecializado" {...register("necessitaAtendimentoEspecializado", { required: watch('deficiente') === 1 ? true : false, valueAsNumber: true })}>
                      <option value=''>SELECIONE</option>
                <option value={1}>SIM</option>
                <option value={0}>NÃO</option>
              </select>
                    {errors?.necessitaAtendimentoEspecializado?.type === 'required' && (
                <span className="text-danger">Campo obrigatório</span>
              )}
            </div>
          </div>

                {
                  watch('necessitaAtendimentoEspecializado') === 1 && (
                    <div className='col-md-2'>
            <div className="form-group">
                    <label htmlFor="tipoDeAtendimento">Tipo de Atendimento<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                        <select className="form-select" id="tipoDeAtendimento" {...register("tipoDeAtendimento", { required: watch('necessitaAtendimentoEspecializado') === 1 ? true : false, valueAsNumber: true })}>
                          <option value=''>SELECIONE</option>
                <option value={1}>CARACTERES AMPLIADOS</option>
                <option value={2}>LEDOR</option>
                <option value={3}>PROFISSIONAL DE APOIO/AUXILIAR</option>
              </select>
                    {errors?.tipoDeAtendimento?.type === 'required' && (
                <span className="text-danger">Campo obrigatório</span>
              )}
            </div>
          </div>
                  )
                }


              </>
            )
            }
          </div>

          {/* {
            watch('deficiente') === 1 && (
              <>
              <div className="row" style={{ marginTop: '30px' }}>
          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              type="file"
              atribute="anexo"
              label="Anexo"
              readOnly={false}
            />
             {!anexoValid &&   (
                <span className="text-danger">Campo obrigatório</span>
              )}
          </div>
        </div>
              </>
               )
              } */}

          
        <div className="row" style={{ marginTop: 30 }}>
          <h4 className='text-dark'>Responsável</h4>
          <hr style={{ marginBottom: '30px' }} />
          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              atribute="responsavelNome"
              label="Nome Completo"
            />
          </div>

          <div className="col-md-2">
            <FormInput
              register={register}
              errors={errors}
              atribute="responsavelRg"
              label="RG"
              required={false}
            />
          </div>

          <div className="col-md-2">
            <FormInput
              register={register}
              errors={errors}
              mask="999.999.999-99"
              atribute="responsavelCpf"
              label="CPF"
            />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {!cpfResponsavelValido && watch('responsavelCpf') !== '' && (
                  <span className="text-danger">CPF Inválido</span>
                )}
                {cpfIsEqual && (
                  <span className="text-danger">CPF não pode ser igual ao do aluno</span>
                )}
              </div>

          </div>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-2">
            <FormInput
              register={register}
              errors={errors}
              type="date"
              atribute="responsavelNascimento"
              label="Data de Nascimento"
            />
          </div>

          

          <div className="col-md-2">
            <FormInput
              register={register}
              errors={errors}
              mask="(99)99999-9999"
              atribute="responsavelTelefone"
              label="Telefone"
            />
          </div>

          <div className="col-md-2">
          <FormInput
              register={register}
              errors={errors}
              type='email'
              atribute="responsavelEmail"
              label="E-mail"
            />
              </div>
        </div>


        <div className="row" style={{ marginTop: 30 }}>
          <h4 className='text-dark'>Escola</h4>
          <hr style={{ marginBottom: '30px' }} />
          {/* <div className="col-md-2">
              <label htmlFor="escolaOrigemId">Escola de Origem<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="escolaOrigemId" value={1} disabled={true}
              {...register("escolaOrigemId", 
              { required: false })}
              onChange={async e => {
                await setTipo(e.target.value)
                // refetchMunicipios()
              }}
              >
              <option></option>
              <option selected="selected" value={1}>MUNICIPAL</option>
              <option value={2}>PRIVADA</option>
              </select>
              {errors.escolaOrigemId?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
            </div> */}
            <div className="col-md-6">
              <label htmlFor="unidadeId">Unidade em que Estuda<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select
                className="form-select"
                id="unidadeId"
                {...register('unidadeId', { required: true })}
              >
                
                  <>
                    {/* <option value=""></option> */}
                    <option></option>
                    {municipios &&
                      municipios?.data.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.descricao}
                        </option>
                      ))}
                  </>
                
              </select>
              {errors.unidadeId?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
            </div>

          {/* {
            watch('unidadeId') === '57' && (
              <div className="col-md-2">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="unidadeOutros"
                  label="Nome da Escola"
                  required={true}
                />
              </div>
            )
          } */}



          <div className="col-md-6">
              <label htmlFor="poloAplicacaoProva ">Polo de Aplicação da Prova<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="poloAplicacaoProva" {...register("poloAplicacaoProva", { required: true })}>
                {/* <option>SELECIONE</option> */}
                <option></option>
                {polos?.data.map(p => (
                  <option key={p.id} value={p.id}>
                    {p.descricao}
                  </option>
                ))}
              </select>
              {errors.poloAplicacaoProva?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
            </div>

          

          
        </div>

        

        

        <div className="row" style={{ marginTop: 30 }}>
          <hr />
          <div className='col-md-12'>
            <label htmlFor="termoDeclaracao">
              {/* <h4>Termo de declaração e ciência:</h4> */}
              <p>Realizei a leitura e concordo com os <a href='/assets/files/prefeituras/EDITAL BILÍNGUE AGRO E TURISMO RURAL.pdf' target="_blank">termos da Edital</a>.*</p></label>

            <div className='col-md-12' style={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" id="termoDeclaracao" name="termoDeclaracao" style={{ marginRight: '12px', width: '20px', height: '20px' }} {...register('termoDeclaracao', { required: true, valueAsNumber: true })} />
              <label for="termoDeclaracao">Declaro que estou ciente</label>
            </div>
          </div>
          {errors?.termoDeclaracao?.type === 'required' && (
            <span className="text-danger" style={{ marginTop: '8px' }} >Concorde com os termos para enviar o formulário.</span>
          )}
        </div>

        <div className="row" style={{ marginTop: 30 }}>
          <div className='col-md-12'>
            <label><h4>Observação importante!</h4>Senhor(a) responsável, informamos que caso o aluno possua um cadastro anterior, o mesmo será cancelado e subtituído pelo novo.</label>
          </div>
        </div>

        {/* <div className="row" style={{ marginTop: 30 }}>
          <hr />
          <div className='col-md-12'>
            <label><h6>*Uma cópia das suas respostas será enviada para o endereço de e-mail fornecido.</h6></label>
          </div>
        </div> */}


      </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '50px' }}>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => navigate('/psbilingue')}
          >
            Voltar
          </button>
          {exibeBotaoSalvar ? 
          <button type="submit" className="btn btn-success">Enviar</button>
         : <></>}
        </div>
      </form>
      </div>



  )
      }
