import React, { useState } from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import { Visibility, AttachFile, Update } from '@mui/icons-material';
import TableComponent from '../../../../components/novaTabela';

const BilingueInscricoesTable = ({ data, handleView, handleUpdate, handleAnexo, totalItems, limit, setLimit, skip, setSkip }) => {
  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Nome", accessor: "nomeCompleto" },
    { Header: "CPF", accessor: "cpf" },
    {
      Header: "Data de Nascimento",
      accessor: "dataNascimento",
      Cell: ({ value }) => {
        const date = new Date(value);
        return date.toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      },
    },
    {
      Header: "Criação",
      accessor: "dataCriacao",
      Cell: ({ value }) => {
        const date = new Date(value);
        return date.toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      },
    },
    { Header: "Status", accessor: "status" },
    {
      Header: "Ações",
      Cell: ({ row }) => (
        <Box>
          <Tooltip title="Visualizar">
            <IconButton color="primary" onClick={() => handleView(row)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Atualizar">
            <IconButton color="secondary" onClick={() => handleUpdate(row)}>
              <Update />
            </IconButton>
          </Tooltip>
          {row.original.anexoId && (
            <Tooltip title="Anexo">
              <IconButton color="success" onClick={() => handleAnexo(row)}>
                <AttachFile />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <TableComponent 
      columns={columns} 
      data={data} 
      hasPagination 
      totalItems={totalItems}
      limit={limit}
      setLimit={setLimit}
      skip={skip}
      setSkip={setSkip}
    />
  );
};

export default BilingueInscricoesTable;
