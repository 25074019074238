import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  Autocomplete
} from '@mui/material';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { getEstados, getCidades } from '../../../../../services/endereco';
import { escolasMunicipais, escolasPrivadas, polosProva, deficiencias, tipoAtendimentoEspecilizado } from './data';
import api from '../../../../../services';
import { criarFormularioBilingue } from '../../../../../services/formulariosService';
import { useNavigate } from 'react-router-dom';
import urlsBilingue from '../../../bilingue/private/urlsBilingue';

// Function to validate CPF
const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove all non-numeric characters
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  let soma = 0, resto;

  for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  return resto === parseInt(cpf.substring(10, 11));
};



const redesEnsino = [
  { label: 'MUNICIPAL' },
  { label: 'PRIVADA' }
];

function FormularioBilingue2024Militar() {
  const [pcd, setPcd] = useState(false);
  const [redeEnsino, setRedeEnsino] = useState('');
  const [cpfValid, setCpfValid] = useState(true);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState('');
  const navigate = useNavigate();
  
  const [formValues, setFormValues] = useState({
    nomeCompleto: '',
    dataNascimento: '',
    cpf: '',
    uf: '',
    cidade: '',
    nomeResponsavel: '',
    rgResponsavel: '',
    cpfResponsavel: '',
    telefoneResponsavel: '',
    emailResponsavel: '',
    redeEnsino: '',
    escola: '',
    polo: '',
    tipoDeficiencia: '',
    atendimentoEspecializado: '',
    anexoId: '',
    anexo: null,
    tipoFormulario: 'BILINGUE_MILITAR',
  });

  function cleanLocalStorage() {
    Object.keys(formValues).forEach((key) => {
      localStorage.removeItem(`bilingueMilitar_${key}`);
    })};

  // Load estados and cidades
  useEffect(() => {
    const fetchEstados = async () => {
      const response = await getEstados();
      setEstados(response?.map((estado) => ({ label: estado.descricao, value: estado.descricao, id: estado.id })));
    };
    fetchEstados();
  }, []);

  useEffect(() => {
    if (estadoSelecionado) {
      const fetchCidades = async () => {
        const estadoId = estados.find((estado) => estado.label === estadoSelecionado)?.id;
        const response = await getCidades(estadoId);
        const sortedCidades = response?.sort((a, b) => a.nome.localeCompare(b.nome));
        setCidades(sortedCidades?.map((cidade) => ({ label: cidade.nome })));
      };
      fetchCidades();
    }
  }, [estadoSelecionado]);

  // Load data from localStorage on mount
  useEffect(() => {
    const savedValues = Object.keys(formValues).reduce((acc, key) => {
      const storedValue = localStorage.getItem(`bilingueMilitar_${key}`);
      if (storedValue) acc[key] = storedValue;
      return acc;
    }, {});
    setFormValues((prev) => ({ ...prev, ...savedValues }));
    setRedeEnsino(savedValues.redeEnsino || '');
    setPcd(savedValues.tipoDeficiencia ? true : false);
  }, []);

  // Save data to localStorage on formValues change
  useEffect(() => {
    Object.keys(formValues).forEach((key) => {
      localStorage.setItem(`bilingueMilitar_${key}`, formValues[key]);
    });
  }, [formValues]);

  

  // CPF Validation
  const handleCpfChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === 'cpf') {
      const numericValue = value.replace(/[^\d]+/g, '');
      if (numericValue.length === 11 && isValidCPF(value)) {
        setCpfValid(true);
      } else if (numericValue.length === 11) {
        setCpfValid(false);
        toast.error('CPF informado inválido');
      }
    }
  };

  const handlePcdChange = (event) => {
    const isPcd = event.target.value === 'sim';
    setPcd(isPcd);
    setFormValues({ ...formValues, tipoDeficiencia: isPcd ? '' : null });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRedeEnsinoChange = (event, newValue) => {
    setRedeEnsino(newValue?.label || '');
    setFormValues({ ...formValues, redeEnsino: newValue?.label || '' });
  };

  const handleEscolaChange = (event, newValue) => {
    setFormValues({ ...formValues, escola: newValue?.label || '' });
  };

  const handlePoloChange = (event, newValue) => {
    setFormValues({ ...formValues, polo: newValue?.label || '' });
  };

  const handleFileChange = (event) => {
    setFormValues({ ...formValues, anexo: event.target.files[0] });
  };

  const handleEstadoChange = (event, newValue) => {
    setEstadoSelecionado(newValue?.value || '');
    setFormValues({ ...formValues, uf: newValue?.value || '' });
  };

  const handleCidadeChange = (event, newValue) => {
    setFormValues({ ...formValues, cidade: newValue?.label || '' });
  };

  async function sendFile(file) {
    if (file?.size / 1000000 > 5) {
      return toast.error('Erro no envio do arquivo, arquivo muito grande, máximo permitido 5MB.');
    }

    if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file?.type)) {
      return toast.error('O anexo deve ser no formato PDF, JPEG ou PNG.');
    }

    const formData = new FormData();
    formData.append('arquivo', file);

    try {
      const sendFile = await api.post('/arquivo/processo-seletivo', formData);
      return sendFile;
    } catch (err) {
      return toast.error('Erro no envio do arquivo. Tente novamente mais tarde.');
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValues.anexo != null && formValues.anexo !== 'null') {
      const response = await sendFile(formValues.anexo);
      setFormValues({ ...formValues, anexoId: response?.data?.data?.id.toLowerCase() || '' });
    }

    if (!redeEnsino || !formValues.escola || !formValues.polo || !formValues.uf || !formValues.cidade) {
      toast.warn('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    if (pcd && (!formValues.tipoDeficiencia || !formValues.atendimentoEspecializado)) {
      toast.warn('Por favor, preencha todos os campos obrigatórios para PCD.');
      return;
    }

    if (pcd && !formValues.anexo && formValues.anexoId === '') {
      toast.warn('Por favor, anexe o laudo médico.');
      return;
    }

    debugger

    var response = await criarFormularioBilingue(formValues);
    if (response.statusCode === 201){
      navigate(urlsBilingue.formulario2024Visualizacao + response.data.id);
      cleanLocalStorage();
    }
  };

  return (
    <Box sx={{ backgroundColor: '#f0f0f0', minHeight: '100vh', padding: '20px' }}>
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          maxWidth: '800px',
          margin: 'auto',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ marginBottom: '20px', textAlign: 'center' }}>
          <img
            src="https://app.educacaoararuama.rj.gov.br/assets/images/prefeituras/seduc.png"
            alt="Logo Prefeitura de Araruama"
            style={{ maxWidth: '150px' }}
          />
          <Typography variant="h5">PREFEITURA MUNICIPAL DE ARARUAMA</Typography>
          <Typography variant="h6">SECRETARIA DE EDUCAÇÃO</Typography>
          <Typography variant="body1" gutterBottom>
            <b>Escola Bilíngue M. Sueli Amaral</b> (Orientação Militar)
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Dados Pessoais</Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nome Completo"
                name="nomeCompleto"
                value={formValues.nomeCompleto}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                type="date"
                label="Data de Nascimento"
                InputLabelProps={{ shrink: true }}
                name="dataNascimento"
                value={formValues.dataNascimento}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <InputMask
                mask="999.999.999-99"
                value={formValues.cpf}
                onChange={handleCpfChange}
              >
                {() => (
                  <TextField
                    fullWidth
                    label="CPF"
                    name="cpf"
                    error={!cpfValid}
                    helperText={!cpfValid && 'CPF inválido'}
                    required
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={estados}
                getOptionLabel={(option) => option.label}
                value={estados.find((estado) => estado.label === formValues.uf) || null}  // Preencher o valor salvo
                onChange={handleEstadoChange}
                renderInput={(params) => <TextField {...params} label="Estado" fullWidth required />}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={cidades}
                getOptionLabel={(option) => option.label}
                value={cidades.find((cidade) => cidade.label === formValues.cidade) || null}  // Preencher o valor salvo
                onChange={handleCidadeChange}
                renderInput={(params) => <TextField {...params} label="Cidade" fullWidth required />}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">Pessoa com Deficiência (PCD)?</Typography>
              <RadioGroup row onChange={handlePcdChange} value={pcd ? 'sim' : 'nao'}>
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
            </Grid>

            {pcd && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    options={deficiencias}
                    getOptionLabel={(option) => option.label}
                    value={deficiencias.find((deficiencia) => deficiencia.label === formValues.tipoDeficiencia) || null}  // Preencher o valor salvo
                    onChange={(event, newValue) =>
                      setFormValues({ ...formValues, tipoDeficiencia: newValue?.label || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="Tipo de Deficiência" />}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    options={tipoAtendimentoEspecilizado}
                    getOptionLabel={(option) => option.label}
                    value={tipoAtendimentoEspecilizado.find((atendimento) => atendimento.label === formValues.atendimentoEspecializado) || null}  // Preencher o valor salvo
                    onChange={(event, newValue) =>
                      setFormValues({ ...formValues, atendimentoEspecializado: newValue?.label || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="Necessita Atendimento Especializado?" />}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1">Anexar Laudo Médico</Typography>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    sx={{ mt: 1 }}
                  >
                    Pesquisar Arquivo
                    <input
                      type="file"
                      hidden
                      onChange={handleFileChange}
                    />
                  </Button>
                  {formValues.anexo && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Arquivo selecionado: {formValues.anexo.name}
                    </Typography>
                  )}
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography variant="h6">Responsável</Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nome Completo"
                name="nomeResponsavel"
                value={formValues.nomeResponsavel}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="RG"
                name="rgResponsavel"
                value={formValues.rgResponsavel}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <InputMask
                mask="999.999.999-99"
                value={formValues.cpfResponsavel}
                onChange={handleCpfChange}
              >
                {() => (
                  <TextField
                    fullWidth
                    label="CPF"
                    name="cpfResponsavel"
                    error={!cpfValid}
                    helperText={!cpfValid && 'CPF inválido'}
                    required
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Telefone"
                name="telefoneResponsavel"
                value={formValues.telefoneResponsavel}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="E-mail"
                name="emailResponsavel"
                value={formValues.emailResponsavel}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Escolha da Unidade Bilíngue</Typography>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                options={redesEnsino}
                getOptionLabel={(option) => option.label}
                value={redesEnsino.find((rede) => rede.label === formValues.redeEnsino) || null}  // Preencher o valor salvo
                onChange={handleRedeEnsinoChange}
                renderInput={(params) => <TextField {...params} label="Estuda em que Rede de Ensino?" fullWidth required />}
              />
            </Grid>

            {redeEnsino === 'MUNICIPAL' && (
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={escolasMunicipais}
                  getOptionLabel={(option) => option.label}
                  value={escolasMunicipais.find((escola) => escola.label === formValues.escola) || null}  // Preencher o valor salvo
                  onChange={handleEscolaChange}
                  renderInput={(params) => <TextField {...params} label="Escolha sua Escola Municipal" />}
                  required
                />
              </Grid>
            )}

            {redeEnsino === 'PRIVADA' && (
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={escolasPrivadas}
                  getOptionLabel={(option) => option.label}
                  value={escolasPrivadas.find((escola) => escola.label === formValues.escola) || null}  // Preencher o valor salvo
                  onChange={handleEscolaChange}
                  renderInput={(params) => <TextField {...params} label="Escolha sua Escola Privada" />}
                  required
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                options={polosProva}
                getOptionLabel={(option) => option.label}
                value={polosProva.find((polo) => polo.label === formValues.polo) || null}  // Preencher o valor salvo
                onChange={handlePoloChange}
                renderInput={(params) => <TextField {...params} label="Polo de Aplicação da Prova" />}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" disabled={!cpfValid}>
                    Enviar
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="secondary" onClick={() => window.history.back()}>
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}

export default FormularioBilingue2024Militar;
