import React from 'react'

import * as S from './styles'

export default function FormField({ label, value, fw }) {
  return (
    <S.Container fieldWidth={fw}>
      <S.SpanLabel>{label.toUpperCase()}</S.SpanLabel>
      <S.FieldValue>{value}</S.FieldValue>
    </S.Container>
  )
}
