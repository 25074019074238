const baseUrlPrivate = '/bilingue';
const baseUrlPublic = '/psbilingue';
const baseUrlPublic2024 = '/bilingue/2025';
const baseUrlPrivate2024 = '/bilingue2025';


const urlsBilingue = {
    dashboard: baseUrlPrivate,
    inscricoes: `${baseUrlPrivate}/inscricoes`,
    indeferir: `${baseUrlPrivate}/indeferir`,
    indeferirId: `${baseUrlPrivate}/indeferir/:id`,
    visualizar: `${baseUrlPrivate}/visualizar/`,
    visualizarId: `${baseUrlPrivate}/visualizar/:id`,
    formulario: `${baseUrlPublic}`,
    formulario2024: `${baseUrlPublic}/formulario`,
    formularioCivico: `${baseUrlPublic}/formulario/civico/`,
    formularioGastronomia: `${baseUrlPublic}/formulario/gastronomia/`,
    formulario2024Militar: `${baseUrlPublic2024}/formulario/militar/`,
    formulario2024VisualizacaoId: `${baseUrlPublic2024}/formulario/visualizacao/:id`,
    formulario2024Visualizacao: `${baseUrlPublic2024}/formulario/visualizacao/`,
    dashboard2024: `${baseUrlPrivate2024}`,
    inscricoes2024: `${baseUrlPrivate2024}/inscricoes`,
    inscricoesAtualizar2024: `${baseUrlPrivate2024}/inscricoes/atualizar`,
}

export default urlsBilingue;