import { useEffect, useState, useMemo } from 'react';
import * as S from './style';
import FormField from '../../../../../components/formField';
import Table from '../../../../../components/table';
import { useParams } from 'react-router-dom'
import api from '../../../../../services';
import { useNavigate } from 'react-router-dom'
import urlsBilingue from '../../private/urlsBilingue';

const BilingueVisualizarFormulario = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { id } = useParams()

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/processoSeletivo/inscricao/${id}`);
      setData(response.data.data)
    }
    fetchData()
  }, [])

  

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      {
        Header: 'Data e Hora', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      { Header: 'Status', accessor: 'status' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <S.TableButton 
            onClick={() =>
              {  window.open(urlsBilingue.visualizar + row.original.id, '_blank')}
              // navigate(urls.processosSeletivosVInscricao + row.original.id)
            }
            className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </S.TableButton>
          </>
        )
      },
    ],
    []
  )

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '24px 48px',
      backgroundImage: 'url("https://pre-matricula.educacaoararuama.rj.gov.br/_form/images/fundo.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      height: '100vh'
    }}>
      <img
        src='https://pre-matricula.educacaoararuama.rj.gov.br/assets/araruama/araruama-logo-principal.png'
        alt="Logo" width="200" height="150"
      />
      <h6 style={{ marginTop: '10px', alignSelf: 'center' }}>PREFEITURA MUNICIPAL DE ARARUAMA</h6>
      <h4 style={{ alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h4>
      <S.Container>
        <S.FormHeader>FORMULÁRIO DE CONFIRMAÇÃO DE INSCRIÇÃO DO PROCESSO SELETIVO 2024</S.FormHeader>

        <S.LineDivider />
        
        <S.Row>
          <FormField label="ID" value={data?.id} fw={25} />
          <FormField label="Data e Hora" value={data?.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + data?.criacao.split('T')[1].split('.')[0]} fw={25} />
          <FormField label="Status" value={data?.status == 1 ? "EM ANÁLISE" : data?.status == 2 ? "CANCELADO" : data?.status == 3 ? "DEFERIDO" : "INDEFERIDO | " + data?.motivo} fw={25} />
          {/* <FormField label="Escola Pretendida" value={data?.tipo == 1 ? "BILÍNGUE SUELI AMARAL (ORIENTAÇÃO MILITAR)" : "BILÍNGUE GETÚLIO VARGAS (GASTRONOMIA E HOTELARIA)"} fw={25} />*/}
           <FormField label="Escola Pretendida" value="ESCOLA BILÍNGUE MUNICIPAL AGRONEGÓCIO & TURISMO" fw={25} /> 
        </S.Row>

        <S.Row>
          <FormField label="nome" value={data?.nome} fw={33.3} />
          <FormField label="data nascimento" value={data?.dataNascimento.split('T')[0].split('-').reverse().join('/')} fw={33.3} />
          <FormField label="cpf" value={data?.cpf} fw={34} />
        </S.Row>
        <S.Row>
          <FormField label="UF" value={data?.estado} fw={50} />
          <FormField label="cidade" value={data?.cidade} fw={50} />
        </S.Row>
        <S.Row>
          <FormField label="deficiente" value={data?.deficiente ? 'SIM' : 'NÃO'} fw={data?.deficiente ? 20 : 100} />
          {
            data?.deficiente && (
              <>
                <FormField label="deficiencia" value={data?.deficienciaDescricao} fw={15} />
                <FormField label="tipo de vaga" value={data?.tipoDeVagaDescricao} fw={20} />
                <FormField label="atendimento especializado" value={data?.necessitaAtendimentoEspecializado ? 'SIM' : 'NÃO'} fw={18} />
                {
                  data?.necessitaAtendimentoEspecializado &&
                  (
                    <FormField label="tipo de atendimento" value={data?.tipoDeAtendimentoDescricao} fw={30} />
                  )
                }
                
              </>
            )
          }
        </S.Row>

        <S.LineDivider />

        <S.Row>
          <FormField label="nome responsável" value={data?.responsavelNome} fw={33.3} />
          <FormField label="rg responsável" value={data?.responsavelRg} fw={33.3} />
          <FormField label="cpf responsável" value={data?.responsavelCpf} fw={34} />
        </S.Row>
        <S.Row>
          <FormField label="Data Nascimento Responsável" value={data?.responsavelNascimento.split('T')[0].split('-').reverse().join('/')} fw={50} />
          <FormField label="Telefone Responsável" value={data?.responsavelTelefone} fw={25} />
          <FormField label="E-mail Responsável" value={data?.responsavelEmail} fw={25} />
        </S.Row>

        <S.LineDivider />

        <S.Row>
          <FormField label="escola de origem" value={data?.escolaOrigemId == 1 ? 'MUNICIPAL' : 'PRIVADA'} fw={33.3} />
          <FormField label="unidade" value={data?.unidadeDescricao} fw={33.3} />
          <FormField label="polo de aplicação" value={data?.poloDescricao} fw={34} />
        </S.Row>

        <S.LineDivider />

        <Table
          columns={columns}
          data={data?.inscricoesAntigas ? data?.inscricoesAntigas : []}
        />

        <S.LineDivider />

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <S.PrintButton type="button" onClick={() => {
            // window.print()
            navigate('/psbilingue')
          }}>Voltar</S.PrintButton>
          <S.PrintButton type="button" onClick={() => {
            window.print()
            navigate('/psbilingue')
          }}>Imprimir</S.PrintButton>
           
        </div>


      </S.Container>
    </div>
  )
}

export default BilingueVisualizarFormulario
