import ProcessoSeletivo2024 from './tab/formulario'


const ProcessoSeletivo2024Form = () => {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 48px',
    }}>
      <img src='https://pre-matricula.educacaoararuama.rj.gov.br/assets/araruama/araruama-logo-principal.png' width="200" height="150" style={{ display: 'flex', alignSelf: 'center' }} />
      <h6 style={{ margin: '15px', alignSelf: 'center' }}>PREFEITURA MUNICIPAL DE ARARUAMA</h6>
      <h4 style={{ alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h4>
      <h2 style={{ margin: '15px', alignSelf: 'center' }}>Inscrição Escola Bilíngue Municipal Agronegócio e Turismo Rural</h2>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                    <div className="tab-content" id="myTabContent">
                    <ProcessoSeletivo2024 />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcessoSeletivo2024Form

