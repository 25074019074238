import React from 'react'

import * as S from './styles'


export default function FormFieldRed({ label, value, fw, nome, anexoId}) {
  return (
    <S.Container fieldWidth={fw}>
      <S.SpanLabel >{label.toUpperCase()}</S.SpanLabel>
      <S.FieldValue><div style={{color: 'red'}}>{value}</div></S.FieldValue>
    </S.Container>
  )
}