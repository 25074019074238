import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormInput from "../../../../../../../../components/formInput";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { listarEscolas } from "../../../../../../../../services/processos/remanejamento";
import { cpf } from "cpf-cnpj-validator";
import urlsRemanejamento from "../../../../../private/urlsRemanejamento";
import api from "../../../../../../../../services";
import moment from "moment";
import { Today } from "@mui/icons-material";
import { postInscricaoRemanejamento } from "../../../../../../../../services/processos/remanejamento";

export default function RemanejamentoFormulario({dataAbertura, dataFechamento}) {
  const navigate = useNavigate();

  const [stateSelected, setStateSelected] = useState();
  const unidade = useState(0);
  const [tipo, setTipo] = useState(0);
  const [cpfAlunoValido, setCpfAlunoValido] = useState(false);
  const [cpfResponsavelValido, setCpfResponsavelValido] = useState(false);
  const [cpfIsEqual, setCpfIsEqual] = useState(false);
  const [dataNascimentoValid, setDataNascimentoValid] = useState(true);

  const [dataNascimentoEarlier, setDataNascimentoEarlier] = useState(true);
  const [dataNascimentoFilhoNaoFez9Anos, setDataNascimentoFilhoNaoFez9Anos] =
    useState(true);
  const [nomeValid, setNomeValid] = useState(false);
  const [anexoValid, setAnexoValid] = useState(false);
  const [cpfValid, setCpfValid] = useState(false);
  const [nomeResponsavel, setNomeResponsavel] = useState(false);
  const [rgResponsavel, setRgResponsavel] = useState(false);
  const [cpfResponsavel, setCpfResponsavel] = useState(false);
  const [dataNascimentoResponsavel, setDataNascimentoResponsavel] =
    useState(false);
  const [telefoneResponsavel, setTelefoneResponsavel] = useState(false);
  const [emailResponsavel, setEmailResponsavel] = useState(false);
  const [exibeBotaoSalvar, setExibeBotaoSalvar] = useState(true);


  //OBJETOS
  // const [nome, setNome] = useState('')
  // console.log(nome.length)

  const [bairrosData, setBairrosData] = useState([]);

  const getBairros = async () => {
    try {
      const res = await api.get(`processoSeletivo/unidade/bairros`);
      setBairrosData(res.data.data);
    } catch (err) {
      console.log({ err });
    }
  };

  const { mutate: mutatePostForm } = useMutation(postInscricaoRemanejamento, {
    onSuccess: (data) => {
      if (data == undefined || data == false) {
        // toast.error('Ocorreu um erro no envio, favor tentar novamente mais tarde.')
      }
      if (data != false && data != undefined) {
        toast.success("Inscrição enviada com sucesso.");
        navigate(urlsRemanejamento.visualizar + data.data.hash);
      }
    },
    onError: (data) => {
      toast.error(
        "Ocorreu um erro no envio, favor tentar novamente mais tarde."
      );
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      nome: "",
      // cpf: '',
      dataNascimento: "",
      deficiente: "",
      bairro: "",
      responsavelNome: "",
      responsavelCpf: "",
      responsavelNascimento: "",
      responsavelTelefone: "",
      escolaridadeId: 0,
      unidadeAtual: 0,
      unidadeOpcao1: 0,
      unidadeOpcao2: 0,
    },
  });

  function removeEmpty(obj) {
    for (const key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }
    return obj;
  }

  function handleErrorWithoutAttachment() {
    toast.error("O envio do anexo é obrigatório.");
  }

  function handleErrorAttachmentNotPDF() {
    toast.error("O anexo deve ser no formato PDF.");
  }

  const onSubmit = async (data) => {
    if (data) {
      // console.log(data)

      if (!nomeValid || !dataNascimentoValid) {
        return toast.error(
          "Todos os campos obrigatórios devem ser preenchidos."
        );
      }
      // if(data?.unidadeId == '57' && data?.unidadeOutros == ''){
      //   return toast.error('Necessário preencher o nome da escola de origem.')
      // }
      else {
        const request = {
          nome: data.nome,
          // cpf: '',
          dataNascimento: data.dataNascimento,
          bairro: data.bairro,
          responsavelNome: data.responsavelNome,
          responsavelCpf: data.responsavelCpf,
          responsavelNascimento: data.responsavelNascimento,
          responsavelTelefone: data.responsavelTelefone,
          escolaridadeId: parseInt(data.escolaridadeId),
          deficiente: Boolean(data?.deficiente),
          unidadeAtual: parseInt(data?.unidadeId),
          unidadeOpcao1: parseInt(data?.unidadeOpcao1),
          unidadeOpcao2: parseInt(data?.unidadeOpcao2),
        };
        console.log(request);
        mutatePostForm(removeEmpty(request));
      }
    }
  };

  function yearBetweenDates(date1, date2) {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24 * 365)
    );
  }

  const { data: escolas2024, refetch: refetchEscolas2024 } = useQuery(
    "listarescolas2024",
    () => (tipo !== 0 ? listarEscolas(2024, tipo) : null),
    {
      retry: 3,
    }
  );

  const { data: escolas2025, refetch: refetchEscolas2025 } = useQuery(
    "listarescolas2025",
    () => (tipo !== 0 ? listarEscolas(2025, tipo) : null),
    {
      retry: 3,
    }
  );

  const validaExibeSalvar = () => {
    var dataAtual = new Date();
    if (dataAtual >= dataAbertura && dataAtual < dataFechamento)
      setExibeBotaoSalvar(true);
  };

  useEffect(() => {
    if (
      bairrosData === undefined ||
      bairrosData === null ||
      bairrosData.length == 0
    ) {
      getBairros();
    }
    validaExibeSalvar();
  });

  useEffect(() => {
    if (
      watch("deficiente") === 0 ||
      (watch("deficiente") !== 1 && watch("deficiente") !== 0)
    ) {
      setValue("deficiencia", null);
      setValue("tipoDeVaga", null);
      setValue("necessitaAtendimentoEspecializado", null);
      setValue("tipoDeAtendimento", null);
    }
    if (
      watch("necessitaAtendimentoEspecializado") === 0 ||
      (watch("necessitaAtendimentoEspecializado") !== 1 &&
        watch("necessitaAtendimentoEspecializado") !== 0)
    ) {
      setValue("tipoDeAtendimento", null);
    }
  }, [watch("deficiente"), watch("necessitaAtendimentoEspecializado")]);

  // useEffect(() => { //Checando se CPF do aluno é válido
  //   const cpfWithoutMask = watch('cpf').replaceAll('.', '').replaceAll('-', '');
  //   const isValidCpf = cpf.isValid(cpfWithoutMask);

  //   setCpfAlunoValido(isValidCpf)
  //   setValue('cpf', cpf.format(cpfWithoutMask))
  // }, [watch('cpf')])

  useEffect(() => {
    //Checando se CPF do responsável é válido
    const cpfWithoutMask = watch("responsavelCpf")
      .replaceAll(".", "")
      .replaceAll("-", "");
    const isValidCpf = cpf.isValid(cpfWithoutMask);

    setCpfResponsavelValido(isValidCpf);
    setValue("responsavelCpf", cpf.format(cpfWithoutMask));
  }, [watch("responsavelCpf")]);

  useEffect(() => {
    if (watch("nome").length > 0) {
      setNomeValid(true);
    } else if (watch("nome").length == 0) {
      setNomeValid(false);
    }
    if (watch("dataNascimento").length > 0) {
      setDataNascimentoValid(true);
      let nascimento = watch("dataNascimento");
      let hoje = moment().format("YYYY-MM-DD");
      if (nascimento.substring(0, 4) > 2014) {
        setDataNascimentoEarlier(false);
      }
      let anoCompletos = yearBetweenDates(nascimento, hoje);
      if (anoCompletos < 9) {
        setDataNascimentoFilhoNaoFez9Anos(true);
      } else {
        setDataNascimentoEarlier(true);
        setDataNascimentoFilhoNaoFez9Anos(false);
      }
    } else if (watch("dataNascimento").length == 0) {
      setDataNascimentoValid(false);
    }
    if (watch("responsavelNome").length > 0) {
      setNomeResponsavel(true);
    } else if (watch("responsavelNome").length == 0) {
      setNomeResponsavel(false);
    }
  }, [watch("nome"), watch("dataNascimento")]);

  return (
    <div
      className="tab-pane fade show active"
      id="listaEspera"
      role="tabpanel"
      aria-labelledby="listaEspera-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card w-100">
          <div className="card-body">
            <div className="row">
              <h4 className="text-dark">Aluno</h4>
              <hr style={{ marginBottom: "30px" }} />
              <div className="col-md-6">
                <FormInput
                  register={register}
                  errors={errors}
                  // onChange={async (e) => {
                  //   await setNome(e.target.value);
                  // }}
                  atribute="nome"
                  label="Nome Completo"
                />
                {/* {!nomeValid &&   (
                <span className="text-danger">Campo obrigatório</span>
              )} */}
              </div>

              <div className="col-md-2">
                <FormInput
                  register={register}
                  errors={errors}
                  type="date"
                  atribute="dataNascimento"
                  label="Data de Nascimento"
                />
              </div>

              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="deficiente">
                    Deficiente?
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="deficiente"
                    {...register("deficiente", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  >
                    <option value=""></option>
                    <option value={1}>SIM</option>
                    <option value={0}>NÃO</option>
                  </select>
                  {errors?.deficiente?.type === "required" && (
                    <span className="text-danger">Campo obrigatório</span>
                  )}
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="bairro">
                    Bairro Onde Reside
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="bairro"
                    {...register("bairro", { required: true })}
                  >
                    <option></option>
                    {bairrosData?.map((bairro) => (
                      <option key={bairro.id} value={bairro.id}>
                        {bairro.descricao}
                      </option>
                    ))}
                  </select>
                  {errors?.bairro?.type === "required" && (
                    <span className="text-danger">Campo obrigatório</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: 30 }}>
              <h4 className="text-dark">Responsável</h4>
              <hr style={{ marginBottom: "30px" }} />
              <div className="col-md-6">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="responsavelNome"
                  label="Nome Completo"
                />
              </div>

              <div className="col-md-2">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="responsavelCpf"
                  label="CPF"
                  mask="999.999.999-99"
                />
              </div>

              <div className="col-md-2">
                <FormInput
                  register={register}
                  errors={errors}
                  type="date"
                  atribute="responsavelNascimento"
                  label="Data de Nascimento"
                />
              </div>

              <div className="col-md-2">
                <FormInput
                  register={register}
                  errors={errors}
                  mask="(99)99999-9999"
                  atribute="responsavelTelefone"
                  label="Telefone"
                />
              </div>
            </div>

            <div className="row" style={{ marginTop: 30 }}>
              <h4 className="text-dark">Escola</h4>
              <hr style={{ marginBottom: "30px" }} />
              <div className="col-md-6">
                <label htmlFor="escolaridadeId">
                  Ano de Escolaridade Cursando
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="escolaridadeId"
                  {...register("escolaridadeId", { required: true })}
                  onChange={async (e) => {
                    // await setValue('escolaId', e.target.value)
                    await setTipo(e.target.value);
                    refetchEscolas2024();
                    refetchEscolas2025();
                  }}
                >
                  <option></option>
                  <option value={1}>CRECHE II</option>
                  <option value={2}>CRECHE III</option>
                  <option value={3}>CRECHE IV</option>
                  <option value={4}>PRÉ II</option>
                  <option value={5}>3º ANO</option>
                  <option value={6}>5º ANO</option>
                </select>
                {errors.escolaOrigemId?.type === "required" && (
                  <div role="alert" className="invalid-feedback d-block">
                    Campo obrigatório!
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="unidadeId">
                  Unidade em que Estuda Atual
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="unidadeId"
                  {...register("unidadeId", { required: true })}
                >
                  <>
                    {/* <option value=""></option> */}
                    <option></option>
                    {escolas2024 &&
                      escolas2024?.data.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.descricao}
                        </option>
                      ))}
                  </>
                </select>
                {errors.unidadeId?.type === "required" && (
                  <div role="alert" className="invalid-feedback d-block">
                    Campo obrigatório!
                  </div>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 30 }}>
              <div className="col-md-6">
                <label htmlFor="unidadeOpcao1">
                  Opção 1 – Escolha a Unidade para Estudar em 2025
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="unidadeOpcao1"
                  {...register("unidadeOpcao1", { required: true })}
                  onChange={async (e) => {
                    // await setValue('unidadeOpcao1', e.target.value)
                  }}
                >
                  <>
                    {/* <option value=""></option> */}
                    <option></option>
                    {escolas2025 &&
                      escolas2025?.data.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.descricao}
                        </option>
                      ))}
                  </>
                </select>
                {errors.escolaOrigemId?.type === "required" && (
                  <div role="alert" className="invalid-feedback d-block">
                    Campo obrigatório!
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="unidadeOpcao2">
                  Opção 2 – Escolha a Unidade para Estudar em 2025{" "}
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="unidadeOpcao2"
                  {...register("unidadeOpcao2", { required: true })}
                >
                  <>
                    {/* <option value=""></option> */}
                    <option></option>
                    {escolas2025 &&
                      escolas2025?.data.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.descricao}
                        </option>
                      ))}
                  </>
                </select>
                {errors.unidadeId?.type === "required" && (
                  <div role="alert" className="invalid-feedback d-block">
                    Campo obrigatório!
                  </div>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 30 }}>
              <hr />
              <div className="col-md-12">
                <label htmlFor="termoDeclaracao">
                  {/* <h4>Termo de declaração e ciência:</h4> */}
                  <p>
                    Realizei a leitura e concordo com os{" "}
                    <a
                      href="/assets/editais/Portaria_131_23_Renovação_de_Matrícula_e_Remanejamento.pdf"
                      target="_blank"
                    >
                      termos da Portaria
                    </a>
                    .*
                  </p>
                </label>

                <div
                  className="col-md-12"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    id="termoDeclaracao"
                    name="termoDeclaracao"
                    style={{
                      marginRight: "12px",
                      width: "20px",
                      height: "20px",
                    }}
                    {...register("termoDeclaracao", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                  <label for="termoDeclaracao">Declaro que estou ciente</label>
                </div>
              </div>
              {errors?.termoDeclaracao?.type === "required" && (
                <span className="text-danger" style={{ marginTop: "8px" }}>
                  Concorde com os termos para enviar o formulário.
                </span>
              )}
            </div>

            <div className="row" style={{ marginTop: 30 }}>
              <div className="col-md-12">
                <label>
                  <h4>Observação importante!</h4>Senhor(a) responsável,
                  informamos que caso o aluno possua um cadastro anterior, o
                  mesmo será cancelado e subtituído pelo novo.
                </label>
                <label>
                  Caso não seja possível atender às opções escolhidas pelo
                  responsável, será ofertada ao aluno, vaga na escola mais
                  próxima de sua residência.
                </label>
                <label>
                  O resultado final será publicado no site
                  educacaoararuama.rj.gov.br, no dia 27 de dezembro de 2024 e,
                  na escola de origem do aluno
                </label>
              </div>
            </div>

            {/* <div className="row" style={{ marginTop: 30 }}>
          <hr />
          <div className='col-md-12'>
            <label><h6>*Uma cópia das suas respostas será enviada para o endereço de e-mail fornecido.</h6></label>
          </div>
        </div> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: 10,
          }}
        >
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => navigate("/remanejamento/formulario")}
          >
            Voltar
          </button>
          {exibeBotaoSalvar ? (
            <button
              type="submit"
              className="btn btn-success"

              // disabled={
              //   cpfIsEqual ||
              //   !cpfAlunoValido ||
              //   !cpfResponsavelValido ||
              //   !isValid ||
              //   (watch('deficiente') === 1 && (watch('deficiencia') === '' ||
              //   watch('tipoDeVaga') === '' ||
              //   watch('necessitaAtendimentoEspecializado') === '') ||
              //   watch('necessitaAtendimentoEspecializado') === 1 && watch('tipoDeAtendimento') === '')
              // }
            >
              Enviar
            </button>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
}
