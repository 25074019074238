const baseUrl = '/bilingue2025';

const urlsBilingue = {
    dashboard: baseUrl,
    inscricoes: `${baseUrl}/inscricoes`,
    inscricoesAtualizar: `${baseUrl}/inscricoes/atualizar`,
    formulario: `${baseUrl}/formulario`,
    formularioMilitar: `${baseUrl}/formulario/militar`,
    formularioGastronomiaHotelaria: `${baseUrl}/formulario/gastronomia-hotelaria`,
    formularioAgronegocioTurismo: `${baseUrl}/formulario/agronegocio-turismo`,
    formularioVisualizacao: `${baseUrl}/formulario/visualizacao/`,
    formularioVisualizacaoId: `${baseUrl}/formulario/visualizacao/:id`,

}

export default urlsBilingue;