import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Link
} from '@mui/material';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { getEstados, getCidades } from '../../../../services/endereco';
import { getEscolasAndPolos } from '../data/getEscolasAndPolos';
import { useNavigate } from 'react-router-dom';
import { criarFormularioBilingue } from '../../../../services/formulariosService';
import urlsBilingue from '../UrlsBilingue';
import { isValidCPF } from '../../../../utils/isValidCpf';
import api from '../../../../services';
import { deficiencias } from '../data/deficiencias';
import { tipoAtendimentoEspecilizado } from '../data/tipoAtendimentoEspecilizado';

// Redes de Ensino options
const redesEnsino = [
  { label: 'MUNICIPAL' },
  { label: 'PRIVADA' }
];

function BilingueFormularioPage({ tipoFormulario, dataFechamento }) {
  // Disable "PRIVADA" option for certain forms
  // if (tipoFormulario === 'BILINGUE_AGRONEGOCIO_TURISMO_RURAL') {
  //   redesEnsino.splice(1, 1); // Remove 'PRIVADA' from the list
  // }

  const [pcd, setPcd] = useState(false); // PCD state
  const [redeEnsino, setRedeEnsino] = useState('');
  const [escolasOptions, setEscolasOptions] = useState([]);
  const [polosOptions, setPolosOptions] = useState([]);
  const [cpfValid, setCpfValid] = useState(true);
  const [cpfResponsavelValid, setCpfResponsavelValid] = useState(true);
  const [dataNascimentoValid, setDataNascimentoValid] = useState(true);
  const [cpfIgual, setCpfIgual] = useState(false);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState('');
  const [aceiteTermo, setAceiteTermo] = useState(false);
  let formularioTitulo;
  let distrito;

  if (tipoFormulario === 'BILINGUE_MILITAR') {
    formularioTitulo = 'Escola Bilíngue M. Sueli Amaral (Orientação Militar)';
    distrito = 'ARARUAMA'
    } else if (tipoFormulario === 'BILINGUE_GASTRONOMIA_HOTELARIA') {
    formularioTitulo = 'Escola Bilíngue M. Prof. João Raposo (Orientação em Gastronomia e Hotelaria)';
    distrito = 'ARAUAMA'
    } else {
    formularioTitulo = 'Escola Bilíngue M. Oscarino Pereira de Andrade (Orientação em Agronegócio e Turismo Rural)';
    distrito = 'SÃO VICENTE DE PAULO'
    }

  const navigate = useNavigate();
  
  const [formValues, setFormValues] = useState({
    nomeCompleto: '',
    dataNascimento: '',
    cpf: '',
    uf: '',
    cidade: '',
    nomeResponsavel: '',
    rgResponsavel: '',
    cpfResponsavel: '',
    telefoneResponsavel: '',
    emailResponsavel: '',
    redeEnsino: '',
    escola: '',
    polo: '',
    tipoDeficiencia: '',
    atendimentoEspecializado: '',
    anexo: null,
    tipoFormulario: tipoFormulario,
  });

  // Save form data to localStorage
  useEffect(() => {
    const savedValues = Object.keys(formValues).reduce((acc, key) => {
      const storedValue = localStorage.getItem(`bilingue_${tipoFormulario}_${key}`);
      if (storedValue) acc[key] = storedValue;
      return acc;
    }, {});
    setFormValues((prev) => ({ ...prev, ...savedValues }));
    setRedeEnsino(savedValues.redeEnsino || '');
    setPcd(savedValues.tipoDeficiencia ? true : false);
  }, [tipoFormulario]);

  // Load estados and cidades
  useEffect(() => {
    const fetchEstados = async () => {
      const response = await getEstados();
      setEstados(response?.map((estado) => ({ label: estado.descricao, value: estado.descricao, id: estado.id })));
    };
    fetchEstados();
  }, []);

  useEffect(() => {
    if (estadoSelecionado) {
      const fetchCidades = async () => {
        const estadoId = estados.find((estado) => estado.label === estadoSelecionado)?.id;
        const response = await getCidades(estadoId);
        const sortedCidades = response?.sort((a, b) => a.nome.localeCompare(b.nome));
        setCidades(sortedCidades?.map((cidade) => ({ label: cidade.nome })));
      };
      fetchCidades();
    }
  }, [estadoSelecionado]);

  // Update escolas and polos based on tipoFormulario and redeEnsino
  useEffect(() => {
    if (tipoFormulario && redeEnsino) {
      const { escolas, polos } = getEscolasAndPolos(tipoFormulario, redeEnsino);
      setEscolasOptions(escolas);
      setPolosOptions(polos);
    }
  }, [tipoFormulario, redeEnsino]);

  const handleCpfChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    
    const numericValue = value.replace(/[^\d]+/g, '');
  
    if (numericValue.length === 11 && isValidCPF(value)) {
      // Valida o CPF e notifica caso não seja válido
      if (name === 'cpf') {
        setCpfValid(true);
      } else if (name === 'cpfResponsavel') {
        setCpfResponsavelValid(true);
      }
    } else if (numericValue.length === 11) {
      // Notifica se o CPF for inválido
      if (name === 'cpf') {
        setCpfValid(false);
        toast.error('CPF informado inválido');
      } else if (name === 'cpfResponsavel') {
        setCpfResponsavelValid(false);
        toast.error('CPF do responsável informado inválido');
      }
    }
  
    // Verifica se os CPFs do aluno e do responsável são iguais
    if ((name === 'cpf' && value === formValues.cpfResponsavel) || (name === 'cpfResponsavel' && value === formValues.cpf)) {
      setCpfIgual(true);
      toast.error('O CPF do aluno e do responsável não podem ser iguais');
    } else {
      setCpfIgual(false);
    }
  };
  

  const handleCpfResponsavelChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === 'cpfResponsavel') {
      const numericValue = value.replace(/[^\d]+/g, '');
      if (numericValue.length === 11 && isValidCPF(value)) {
        setCpfResponsavelValid(true);
      } else if (numericValue.length === 11) {
        setCpfResponsavelValid(false);
        toast.error('CPF do responsável informado inválido');
      }

      // Check if CPF of aluno and responsável are the same
      if (formValues.cpf === value) {
        setCpfIgual(true);
        toast.error('CPF do aluno e responsável não podem ser iguais');
      } else {
        setCpfIgual(false);
      }
    }
  };

  const handleDataNascimentoChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    const minDate = new Date('2012-04-01');
    const selectedDate = new Date(value);
    if (selectedDate < minDate) {
      setDataNascimentoValid(false);
      // toast.error('Data de nascimento deve ser a partir de 01/04/2012');
    } else {
      setDataNascimentoValid(true);
    }
  };

  const handleAceiteTermoChange = (e) => {
    setAceiteTermo(e.target.checked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRedeEnsinoChange = (event, newValue) => {
    setRedeEnsino(newValue?.label || '');
    setFormValues({ ...formValues, redeEnsino: newValue?.label || '', escola: '', polo: '' });
  };

  const handleEscolaChange = (event, newValue) => {
    setFormValues({ ...formValues, escola: newValue || '' });
  };

  const handlePoloChange = (event, newValue) => {
    setFormValues({ ...formValues, polo: newValue || '' });
  };

  const handleEstadoChange = (event, newValue) => {
    setEstadoSelecionado(newValue?.value || '');
    setFormValues({ ...formValues, uf: newValue?.value || '' });
  };

  const handleCidadeChange = (event, newValue) => {
    setFormValues({ ...formValues, cidade: newValue?.label || '' });
  };

  const handleFileChange = (event) => {
    setFormValues({ ...formValues, anexo: event.target.files[0] });
  };

  const handlePcdChange = (e) => {
    const isPcd = e.target.value === 'sim';
    setPcd(isPcd);
    setFormValues({ ...formValues, tipoDeficiencia: isPcd ? '' : null });
  };

  const handleSubmit = async (event) => {

    if (new Date() > dataFechamento) {
      toast.error('Formulário fora do prazo de inscrição, incriçoes encerradas.');
      navigate(urlsBilingue.formulario);
      return;
    }
    event.preventDefault();
    if (!redeEnsino || !formValues.escola || !formValues.polo || !formValues.uf || !formValues.cidade) {
      toast.warn('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    if (pcd && (!formValues.tipoDeficiencia || !formValues.atendimentoEspecializado)) {
      toast.warn('Por favor, preencha todos os campos obrigatórios para PCD.');
      return;
    }

    if (pcd && !formValues.anexo) {
      toast.warn('Por favor, anexe o laudo médico obrigatório.');
      return;
    }

    if (isValidCPF(formValues.cpf) === false) {
      toast.error('CPF informado inválido.');
      return;
    }

    if (isValidCPF(formValues.cpfResponsavel) === false) {
      toast.error('CPF do responsável informado inválido.');
      return;
    }
    
    if (formValues.cpf === formValues.cpfResponsavel) {
      toast.error('CPF do aluno e do responsável não podem ser iguais.');
      return;
    }

    let anexoResponse;

    // Perform file upload logic
    if (formValues.anexo) {
      const formData = new FormData();
      formData.append('arquivo', formValues.anexo);
      anexoResponse = await api.post('/arquivo/processo-seletivo', formData);

      if (anexoResponse?.data?.statusCode !== 201) {
        return toast.error('Erro ao enviar arquivo.');
      }
      if (formValues.anexo && !anexoResponse?.data?.data?.id) {
        return toast.error('Erro ao enviar arquivo..');
      }
      // debugger
      // setFormValues({ ...formValues, anexoId: response?.data?.data?.id });
    }

   

    if (aceiteTermo === false) {
      return toast.error('É necessário aceitar os termos do edital.');
    }

    const data = {
      ...formValues,
      anexoId: anexoResponse?.data?.data?.id,
    }
    const response = await criarFormularioBilingue(data);
    if (response.statusCode === 201) {
      toast.success('Formulário enviado com sucesso!');
      navigate(urlsBilingue.formularioVisualizacao + response.data.id);
    } else {
      toast.error('Erro ao enviar formulário.');
    }
  };



  return (
    <Box sx={{ backgroundColor: '#f0f0f0', minHeight: '100vh', padding: '20px' }}>
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          maxWidth: '800px',
          margin: 'auto',
          backgroundColor: 'white',
        }}
      >

<Box sx={{ marginBottom: '20px', textAlign: 'center' }}>
          <img
            src="https://app.educacaoararuama.rj.gov.br/assets/images/prefeituras/seduc.png"
            alt="Logo Prefeitura de Araruama"
            style={{ maxWidth: '150px' }}
          />
          <Typography variant="h5">PREFEITURA MUNICIPAL DE ARARUAMA</Typography>
          <Typography variant="h6">SECRETARIA DE EDUCAÇÃO</Typography>
          <Typography variant="body1" gutterBottom>
          <b>{formularioTitulo}</b><br /><b>{distrito}</b>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Dados Pessoais */}
            <Grid item xs={12}>
              <Typography variant="h6">Dados Pessoais</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nome Completo"
                name="nomeCompleto"
                value={formValues.nomeCompleto}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="date"
                label="Data de Nascimento"
                InputLabelProps={{ shrink: true }}
                name="dataNascimento"
                value={formValues.dataNascimento}
                onChange={handleDataNascimentoChange}
                error={!dataNascimentoValid}
                helperText={!dataNascimentoValid && 'Data de nascimento deve ser a partir de 01/04/2012.'}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask="999.999.999-99"
                value={formValues.cpf}
                onChange={handleCpfChange}
              >
                {() => (
                  <TextField
                    fullWidth
                    label="CPF"
                    name="cpf"
                    error={!cpfValid}
                    helperText={!cpfValid && 'CPF inválido'}
                    required
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={estados}
                getOptionLabel={(option) => option.label}
                value={estados.find((estado) => estado.label === formValues.uf) || null}  // Preencher o valor salvo
                onChange={handleEstadoChange}
                renderInput={(params) => <TextField {...params} label="Estado" fullWidth required />}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={cidades}
                getOptionLabel={(option) => option.label}
                value={cidades.find((cidade) => cidade.label === formValues.cidade) || null}  // Preencher o valor salvo
                onChange={handleCidadeChange}
                renderInput={(params) => <TextField {...params} label="Cidade" fullWidth required />}
              />
            </Grid>

            {/* Pessoa com Deficiência (PCD) */}
            <Grid item xs={12}>
              <Typography variant="h6">Pessoa com Deficiência (PCD)</Typography>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup row onChange={handlePcdChange} value={pcd ? 'sim' : 'nao'}>
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
            </Grid>
            {pcd && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    options={deficiencias}
                    getOptionLabel={(option) => option.label}
                    value={deficiencias.find((deficiencia) => deficiencia.label === formValues.tipoDeficiencia) || null}  // Preencher o valor salvo
                    onChange={(event, newValue) =>
                      setFormValues({ ...formValues, tipoDeficiencia: newValue?.label || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="Tipo de Deficiência" />}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    options={tipoAtendimentoEspecilizado}
                    getOptionLabel={(option) => option.label}
                    value={tipoAtendimentoEspecilizado.find((atendimento) => atendimento.label === formValues.atendimentoEspecializado) || null}  // Preencher o valor salvo
                    onChange={(event, newValue) =>
                      setFormValues({ ...formValues, atendimentoEspecializado: newValue?.label || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="Necessita Atendimento Especializado?" />}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1">Anexar Laudo Médico</Typography>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    sx={{ mt: 1 }}
                  >
                    Pesquisar Arquivo
                    <input
                      type="file"
                      hidden
                      onChange={handleFileChange}
                    />
                  </Button>
                  {formValues.anexo && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Arquivo selecionado: {formValues.anexo.name}
                    </Typography>
                  )}
                </Grid>
              </>
            )}

            {/* Responsável */}
            <Grid item xs={12}>
              <Typography variant="h6">Responsável</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nome do Responsável"
                name="nomeResponsavel"
                value={formValues.nomeResponsavel}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="RG do Responsável"
                name="rgResponsavel"
                value={formValues.rgResponsavel}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask="999.999.999-99"
                value={formValues.cpfResponsavel}
                onChange={handleCpfResponsavelChange}
              >
                {() => (
                  <TextField
                    fullWidth
                    label="CPF do Responsável"
                    name="cpfResponsavel"
                    error={!cpfResponsavelValid}
                    helperText={!cpfResponsavelValid && 'CPF inválido'}
                    required
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Telefone do Responsável"
                name="telefoneResponsavel"
                value={formValues.telefoneResponsavel}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email do Responsável"
                name="emailResponsavel"
                value={formValues.emailResponsavel}
                onChange={handleInputChange}
                required
              />
            </Grid>

            

            {/* Escola, Polo, and Form submission */}
            <Grid item xs={12}>
              <Typography variant="h6">Rede de Ensino a que pertence</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={redesEnsino}
                getOptionLabel={(option) => option.label}
                value={redesEnsino.find((rede) => rede.label === formValues.redeEnsino) || null}  
                onChange={handleRedeEnsinoChange}
                renderInput={(params) => <TextField {...params} label="Estuda em que Rede de Ensino?" fullWidth required />}
              />
            </Grid>

            {redeEnsino && (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    options={escolasOptions}
                    getOptionLabel={(option) => option}
                    value={formValues.escola}
                    onChange={handleEscolaChange}
                    renderInput={(params) => <TextField {...params} label="Escolha sua Escola" />}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Escolha o Local da Prova</Typography>
                  <Autocomplete
                    fullWidth
                    options={polosOptions}
                    getOptionLabel={(option) => option}
                    value={formValues.polo}
                    onChange={handlePoloChange}
                    renderInput={(params) => <TextField {...params} label="Escolha seu Polo" />}
                    required
                  />
                </Grid>
                </>
            )}

            {/* Termo de Aceite */}
            <Grid item xs={12}>
              <Typography variant="body1">
                Realizei a leitura e concordo com os{' '}
                <Link href="/assets/files/prefeituras/EDITAL BILÍNGUE 2025.pdf" target="_blank">
                  termos do Edital
                </Link>
                .*
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={aceiteTermo}
                    onChange={handleAceiteTermoChange}
                    name="aceiteTermo"
                    color="primary"
                  />
                }
                label="Declaro que estou ciente e concordo com os termos do edital"
              />
            </Grid>

            <Grid item xs={12}>
              <Button className="no-print" variant="contained" color="primary" type="submit" disabled={!cpfValid || !cpfResponsavelValid || !dataNascimentoValid || cpfIgual}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}

export default BilingueFormularioPage;
