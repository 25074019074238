import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { listarLancamentosTurmaRiap } from '../../../services/ficha-avaliacao';
import PDFGenerator from '../../../utils/pdfGenerator';
import DataImpressao from './dataImpressao';
import { useEffect } from 'react';
import './estilo.css';
import { store } from '../../../store';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Signature from '../../../utils/signature';

const FichaAvaliacaoImpressaoRiapEJA= () => {
  const [data, setData] = useState([]);

  const turmaId = localStorage.getItem('turmaIdEjaRiap')
  const disciplina = localStorage.getItem('disciplinaIdEjaRiap')

  const currentUser = store.getState()['user']['user'];
  const today = new Date();
  const formattedDate = format(today, "dd 'de' MMMM 'de' yyyy 'às' HH:mm", { locale: ptBR });

  const { refetch } = useQuery('listarLancamentosEja', () =>
    turmaId !== null && disciplina != null ? listarLancamentosTurmaRiap(turmaId, disciplina) : null,
    {
      retry: 3,
      enabled: turmaId !== 0,
      onSuccess: (data) => {
        debugger
        if (data === undefined) {
          setData([]);
          return;
        }
        setData(data?.data);
      },
      onError: (error) => {
        console.error('An error occurred:', error);
        setData([]);
      },
    }
  );



  useEffect(() => {
    let timer;

    const handlePrint = () => {
        timer = setTimeout(() => {
            // window.print();
        }, 2000);
    };

    const handleAfterPrint = () => {
        clearTimeout(timer);
        window.removeEventListener('afterprint', handleAfterPrint);
        // window.close(); // Close the window after printing
    };

    window.addEventListener('afterprint', handleAfterPrint);

    handlePrint();

    return () => {
        clearTimeout(timer);
        window.removeEventListener('afterprint', handleAfterPrint);
    };
}, []);
  


  return (
    <>
      <style>
        {`
          @media print {
            @page {
              size: landscape;
            }
          }
        `}
      </style>
      <div className='row' id='impressao-ficha-infantil' style={{backgroundColor: 'white'}}>
        <div className='col-12'>
          <div className='card'>
            <div className="card-body" style={{backgroundColor: 'fff'}}>
              {data !== undefined && (
                <div className="form-group">
                    <img src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} height={90} className='center' style={{ marginBottom: '20px', marginTop: '0' }} alt="Logo" />
                    <h4 style={{ textAlign: 'center' }}>ESTADO DO RIO DE JANEIRO | PREFEITURA MUNICIPAL DE ARARUAMA | {data?.[0]?.unidade}</h4>
            <h5 style={{ textAlign: 'center', marginBottom: '10px' }}>TURMA: {data?.[0]?.turma} | ESCOLARIDADE: {data?.[0]?.escolaridade} | TURNO: {data?.[0]?.turno}</h5>
                  {/* {data.length > 0 && <HeaderImpressao data={data} />}
                  <br /> */}
                  {data.length > 0 && <DataImpressao data={data} />}
                  <p style={{ marginTop: 20 }}>Total Aulas Dadas: {data?.[0]?.aulasDadas + data?.[1]?.aulasDadas}</p>
                <p style={{ marginTop: 20 }}>Encerrado Em:</p>
                <p style={{ marginTop: 20, marginBottom: 100 }}>Observações:</p>
                <Signature />
                  
                  <br />
                  <label style={{textAlign: 'left'}}>Usuário Impressão: {currentUser.usuarioLogin} | Data Impressão: {formattedDate}</label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FichaAvaliacaoImpressaoRiapEJA;
